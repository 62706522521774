import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

import Slider from 'react-slick';

import Img1 from '../assets/images/testi-img/img-1.png';
import Img2 from '../assets/images/testi-img/img-2.png';
import Img3 from '../assets/images/testi-img/img-3.png';
import Img4 from '../assets/images/testi-img/img-4.png';

import HomeUrl from '../assets/images/home-border.png';

import Client1 from '../assets/images/clients/1.png';
import Client2 from '../assets/images/clients/2.png';
import Client3 from '../assets/images/clients/3.png';
import Client4 from '../assets/images/clients/4.png';

export default class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slideitems: [
                {
                    id: 100,
                    img: Img1,
                    name: 'Brandon Carney',
                    designation: 'Designer',
                    description:
                        'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.',
                },
                {
                    id: 101,
                    img: Img2,
                    name: 'Samuel Campbell',
                    designation: 'Developer',
                    description:
                        'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.',
                },
                {
                    id: 103,
                    img: Img3,
                    name: 'Michelle Stehle',
                    designation: 'Manager',
                    description:
                        'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.',
                },
                {
                    id: 104,
                    img: Img4,
                    name: 'Margaret Lampley',
                    designation: 'CEO',
                    description:
                        'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.',
                },
            ],
        };
    }
    render() {
        var slidesettings = {
            dots: true,
            speed: 300,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            dotsClass: 'slick-dots slick-thumb carousel-indicators',
            customPaging: function (i) {
                return (
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/images/img-' +
                            (i + 1) +
                            '.jpg'
                        }
                        alt=''
                        className=' testi-img img-fluid rounded mx-auto d-block'
                    />
                );
            },
        };

        const clientslides = this.state.slideitems.map(
            (slideitem, clientkey) => {
                return (
                    <div className='carousel-item' key={slideitem.id}>
                        <Row className='align-items-center'>
                            <Col lg='6'>
                                <div className='client-box mt-4'>
                                    <h5 className='line-height_1_6'>
                                        {slideitem.description}
                                    </h5>
                                    <div className='client-icon'>
                                        <i className='mdi mdi-format-quote-close'></i>
                                    </div>
                                    <h5 className='f-18'>{slideitem.name} </h5>
                                    <p className='text-primary mb-0'>
                                        - {slideitem.designation}{' '}
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='text-center mt-4'>
                                    <img
                                        src={slideitem.img}
                                        className='img-fluid'
                                        alt=''
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            }
        );

        return (
            <React.Fragment>
                {/* <section className="section" id="clients">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">Clients Loved Products</h3>
                  <p className="text-muted f-17 mt-3">Vivamus ac nulla ultrices laoreet neque mollis mi morbi
                  elementum mauris
                            sit amet arcu <br /> fringilla auctor In eleifend maximus nisi sed vulputate.</p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              <Col lg="12">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <Slider {...slidesettings}>
                      {clientslides}
                    </Slider>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
                <section
                    className='section bg-clients'
                    style={{ padding: '0px 0px 100px 0px' }}
                >
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className='title-box text-center'>
                                    <h3 className='title-heading mt-4'>
                                        Check out what else BizDateUp got for
                                        you
                                    </h3>
                                    <p className='text-muted f-17 mt-3'>
                                        We know running a startup isn’t a
                                        childsplay, thus we come up with
                                        services to help you thrive.
                                    </p>
                                    <img
                                        src={HomeUrl}
                                        height='15'
                                        className='mt-3'
                                        alt=''
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="mt-5 pt-4 ">
              <div className="card-deck border-0 shadow-none ">
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-title mt-4 h-100 py-4 "
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Pitch Deck
                    </h4>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-title mt-4 h-100 "
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Mentorship Sessions
                    </h4>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-title mt-4 h-100 py-4 "
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Legal Solutions
                    </h4>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-title mt-4 h-100  "
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Web & App Development
                    </h4>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-title mt-4 h-100 py-4 "
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Recruitment
                    </h4>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-title mt-4 h-100"
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Employee Financial Planning
                    </h4>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-titale mt-4 h-100"
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Talent Management
                    </h4>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className="card border-0 h-100 shadow-none">
                    <h4
                      className="card-title mt-4 h-100"
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        background: '#202054',
                        borderRadius: '5px',
                        color: '#ffffff',
                      }}
                    >
                      Market Consultancy
                    </h4>
                  </div>
                </Col>
              </div>
            </Row> */}

                        {/* ========================================================================= */}
                        <div className='row row row-cols-1 row-cols-sm-2  row-cols-md-2 row-cols-lg-4 '>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/external-flaticons-lineal-color-flat-icons/128/000000/external-pitch-influencer-marketing-flaticons-lineal-color-flat-icons-3.png'
                                    />
                                    {/* <h6>Pitch Deck</h6> */}

                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title'>
                                            Pitch Deck
                                        </h5>
                                        <p class='newsCaption-content'>
                                            Pitch deck is used to pitch your
                                            idea or company to any number of
                                            audiences, generally investors.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/external-wanicon-flat-wanicon/128/000000/external-mentor-training-and-coaching-wanicon-flat-wanicon.png'
                                    />
                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title '>
                                            Mentorship Sessions
                                        </h5>
                                        <p class='newsCaption-content'>
                                            Influence, guidance or direction
                                            given by a mentor.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/external-icongeek26-glyph-icongeek26/128/000000/external-legal-business-and-finance-icongeek26-glyph-icongeek26.png'
                                    />
                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title'>
                                            Legal Solutions
                                        </h5>
                                        <p class='newsCaption-content'>
                                            Rendering of any legal services like
                                            documentation of the company and
                                            many more.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/external-justicon-lineal-color-justicon/128/000000/external-app-development-responsive-web-design-justicon-lineal-color-justicon.png'
                                    />
                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title1'>
                                            Web & App Development
                                        </h5>
                                        <p class='newsCaption-content1'>
                                            Get your own personalised
                                            application and website.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-n5 row row-cols-1 row-cols-sm-2  row-cols-md-2 row-cols-lg-4 '>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/external-flaticons-lineal-color-flat-icons/128/000000/external-recruitment-recruitment-agency-flaticons-lineal-color-flat-icons-8.png'
                                    />
                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title'>
                                            Recruitment
                                        </h5>
                                        <p class='newsCaption-content'>
                                            Seek, find and hire exceptional
                                            candidates for a any position or a
                                            job.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/color/128/000000/men-age-group-5.png'
                                    />
                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title1 '>
                                            Employee Financial Planning
                                        </h5>
                                        <p class='newsCaption-content1'>
                                            Determine the financial goals for
                                            the employee.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/external-flaticons-lineal-color-flat-icons/128/000000/external-talent-management-human-resources-flaticons-lineal-color-flat-icons.png'
                                    />
                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title'>
                                            Talent Management
                                        </h5>
                                        <p class='newsCaption-content'>
                                            Recruit and develop a workforce that
                                            is as productive as possible.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col home_services'>
                                <div class='newsCard news-Slide-up'>
                                    <img
                                        className='ico'
                                        src='https://img.icons8.com/external-flat-land-kalash/128/000000/external-market-finance-and-banking-flat-land-kalash.png'
                                    />
                                    <div class='newsCaption'>
                                        <h5 class='newsCaption-title'>
                                            Market Consultancy
                                        </h5>
                                        <p class='newsCaption-content'>
                                            Professional services provided by
                                            experienced and qualified experts in
                                            the field of marketing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}
