import React from 'react'
import spinner from '../assets/images/spinner.gif'


const Spinner = () => <>

<img src={spinner} alt="Loading..." style={{width:'200px', margin :'auto',display:'block',backgroundColor:'white',zIndex:'300',backgroundSize:'cover',WebkitBackgroundSize:'cover'  }} />
</>
  

export default Spinner ; 
