import React, { Component } from 'react';

import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import HomeUrl from '../assets/images/home-border.png';
import Feature from '../assets/images/features/img-3.png';
import NavBar from './Navbar/NavBar';

import { Link } from 'react-scroll';
import '../assets/css/style.css';

//sidebar data
const riskSidebar = [
  {
    id: '1',
    title: 'Investment Loss',
    path: '1',
  },
  {
    id: '2',
    title: 'Illiquid Investments',
    path: '2',
  },
  {
    id: '3',
    title: 'Untimely Payment of Dividendss',
    path: '3',
  },
  {
    id: '4',
    title: 'Dilution of Holdings',
    path: '4',
  },
  {
    id: '5',
    title: 'Forward-Looking Statements',
    path: '5',
  },
  {
    id: '6',
    title: 'Taxation Policy',
    path: '6',
  },
];

const Risk = () => {
  return (
    <React.Fragment>
      <main>
        <section>
          <div className="sidebar">
            <ul>
              {riskSidebar.map((item, id) => {
                return (
                  <>
                    <li className="listitem" key={id}>
                      <Link
                        activeClass="active"
                        to={item.path}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        className="linkstyle3"
                      >
                        {item.title}
                      </Link>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </section>
      </main>

      <section className="section" id="contact">
        <Container style={{ marginLeft: '20%' }}>
          <section id="1">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Investment Loss
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    Do you know that capital loss is a danger in
                    start-up investments? The reason why experts
                    suggest diversifying your portfolio with different
                    investments is because if you put all your eggs in
                    one basket, your probability for a major loss is
                    much more. There is no guarantee of promisible
                    returns and profits in start-up investments as
                    these start-ups do not have a 100% possibility of
                    succeeding. Thus, if you diversify your portfolio
                    you mitigate the risk of facing losses on your
                    investment.
                  </p>
                  <img
                    src={HomeUrl}
                    height="15"
                    className="mt-3"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </section>

          <section id="2">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Illiquid Investments
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    The term liquidity stands for how easily you can
                    convert your holdings into cash. Private
                    investments are not often listed on the stock
                    exchange; thus they are tricky to sell off.
                    Start-up investments fall under the category of
                    Private investments, which makes them extremely
                    illiquid as you cannot easily convert them into
                    cash.
                  </p>
                  <img
                    src={HomeUrl}
                    height="15"
                    className="mt-3"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </section>

          <section id="3">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Untimely Payment of Dividends
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    In general, start-ups are unable to pay dividends
                    at any point during the financing cycle. As a
                    result, in order to make any returns or profits,
                    you must go through another sale or a similar
                    process for which there is no time span
                    guaranteed.
                  </p>
                  <img
                    src={HomeUrl}
                    height="15"
                    className="mt-3"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </section>

          <section id="4">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Dilution of Holdings
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    A start-up requires constant capital to run its
                    daily chores and functioning. To meet the needs,
                    additional capital might be required. As a result,
                    news shares can be allotted. Thus, the previous
                    holdings can be affected or diluted due to the
                    issuance of new shares.{' '}
                  </p>
                  <img
                    src={HomeUrl}
                    height="15"
                    className="mt-3"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </section>

          <section id="5">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Forward-Looking Statements
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    Statements with a View towards the future made by
                    the startup, including opinions and judgments, are
                    based on a variety of estimates and assumptions
                    that are subject to severe business, economic,
                    regulatory, and competitive risks. Even if they
                    can be utilised to understand the businesses'
                    intentions and goals, these remarks should not be
                    considered as undertakings by the startups, but
                    rather as speculative and subjective in nature.
                  </p>
                  <img
                    src={HomeUrl}
                    height="15"
                    className="mt-3"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </section>

          <section id="6">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Taxation Policy
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    Taxes are primarily the responsibility of
                    individuals. It is always suggested that you
                    consult with a tax advisor before making any
                    decisions. Dividends and returns in start-ups
                    maybe subjective to taxation. As a result, in
                    order to avoid any potential issues, you undertake
                    to hold Bizdateup blames and not pursue any claims
                    in connection with the foregoing.
                  </p>
                  <img
                    src={HomeUrl}
                    height="15"
                    className="mt-3"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Risk;
