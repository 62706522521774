import React, { Component, useEffect } from 'react';
import {
    Navbar,
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavbarDropdown,
    NavItem,
    NavLink,
    Container,
    Collapse,
    ButtonDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

// Import Logo
import logodark from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';

import ScrollspyNav from './Scrollspy';

class NavbarPage extends Component {
    // render() {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMenu: false,
            check: localStorage.getItem('loggedIn') !== null ? true : false,
            user: JSON.parse(localStorage.getItem('loggedIn')),
            reload: true,
            dropdownOpen: false,
        };

        // this.toggle = this.toggle.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);
    }

    // toggle = () => {
    //     this.setState({ isOpenMenu: !this.state.isOpenMenu });
    //     this.setState((prevState) => ({
    //         dropdownOpen: !prevState.dropdownOpen,
    //     }));
    // };

    toggleMenu = () => {
        this.setState({ isOpenMenu: !this.state.isOpenMenu });
    };
    toggleDropDown = () => {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    // componentDidUpdate(){
    //   this.setState({check:localStorage.getItem('loggedIn')!==null?true:false});
    // }
    logout() {
        localStorage.clear();
        window.location.reload();
    }

    logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    render() {
        let targetId = this.props.navItems.map((item) => {
            return item.idnm;
        });

        return (
            <React.Fragment>
                <Navbar
                    expand='lg'
                    fixed={this.props.top === true ? 'top' : ''}
                    className={
                        this.props.navClass +
                        ' navbar-custom sticky sticky-dark'
                    }
                    id='navbar'
                >
                    <Container>
                        {/* LOGO */}
                        <NavbarBrand
                            className='navbar-brand logo text-uppercase'
                            href='/'
                        >
                            {this.props.imglight === true ? (
                                <img src={logodark} alt='' height='40' />
                            ) : (
                                <img src={logodark} alt='' height='40' />
                            )}
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleMenu}>
                            <i className='mdi mdi-menu'></i>
                        </NavbarToggler>
                        <Collapse
                            id='navbarCollapse'
                            isOpen={this.state.isOpenMenu}
                            className=' navbar-collapse'
                        >
                            <ScrollspyNav
                                scrollTargetIds={targetId}
                                scrollDuration='800'
                                headerBackground='true'
                                activeNavClass='active'
                                className='navbar-collapse'
                            >
                                <Nav
                                    className='navbar-nav ml-auto navbar-center'
                                    id='navbar-navlist'
                                >
                                    {this.props.navItems.map((item, key) => (
                                        <NavItem
                                            key={key}
                                            className={
                                                item.navheading === 'Home'
                                                    ? 'active'
                                                    : ''
                                            }
                                        >
                                            <NavLink
                                                className={
                                                    item.navheading === 'Home'
                                                        ? 'active'
                                                        : ''
                                                }
                                                href={'/' + item.idnm}
                                            >
                                                {item.navheading}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </ScrollspyNav>
                            {!this.state.check ? (
                                <ul className='navbar-nav navbar-center'>
                                    <li className='nav-item'>
                                        <Link to='/' className='nav-link'>
                                            Home
                                        </Link>
                                    </li>

                                    <li className='nav-item'>
                                        <Link to='/About' className='nav-link'>
                                            About Us
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/Deals' className='nav-link'>
                                            Deals
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/Match' className='nav-link'>
                                            Match
                                        </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link to='/Invest' className='nav-link'>
                                            Invest
                                        </Link>
                                    </li>

                                    <li className='nav-item'>
                                        <a
                                            href='https://www.learn.bizdateup.com/'
                                            target='_self'
                                            className='nav-link'
                                        >
                                            Learn
                                        </a>
                                    </li>

                                    <li className='nav-item'>
                                        <Link to='/Login' className='nav-link'>
                                            Login
                                        </Link>
                                    </li>
                                    <li className='nav-item d-inline-block d-lg-none'>
                                        <Link to='/Signup' className='nav-link'>
                                            Sign Up
                                        </Link>
                                    </li>
                                </ul>
                            ) : (
                                <div>
                                    <ul className='navbar-nav navbar-center'>
                                        <li className='nav-item'>
                                            <Link to='/' className='nav-link'>
                                                Home
                                            </Link>
                                        </li>

                                        <li className='nav-item'>
                                            <Link
                                                to='/About'
                                                className='nav-link'
                                            >
                                                About Us
                                            </Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link
                                                to='/Deals'
                                                className='nav-link'
                                            >
                                                Deals
                                            </Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link
                                                to='/Match'
                                                className='nav-link'
                                            >
                                                Match
                                            </Link>
                                        </li>
                                        <li className='nav-item'>
                                            <a
                                                href='https://www.learn.bizdateup.com/'
                                                target='_self'
                                                className='nav-link'
                                            >
                                                Learn
                                            </a>
                                        </li>

                                        {/* <li className="nav-item">
                    <Link to="/Invest"   className="nav-link">Logout</Link>
                    <Link to="/Profile"  className="nav-link">Profile</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Invest"   className="nav-link">Logout</Link>
                    <Link onClick={() => this.logout()}  className="nav-link">Logout</Link>
                  </li> */}
                                    </ul>
                                </div>
                            )}

                            {!this.state.check ? (
                                <div className='navbar-button d-none d-lg-inline-block'>
                                    <Link
                                        to='/Signup'
                                        className='btn btn-sm btn-soft-primary btn-round'
                                    >
                                        Sign Up
                                    </Link>
                                </div>
                            ) : (
                                // <div className="dropdown">

                                <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.toggleDropDown}
                                >
                                    <DropdownToggle className='  btn-sm btn-soft-primary btn-round border-0'>
                                        {
                                            ` ${this.state.user?.first_name} ${this.state.user?.last_name}`

                                            // ` ${this.state.firstName} ${this.state.lastName}`
                                        }
                                        <i class='fa fa-chevron-down ml-2'></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            className='pr-0 m-0'
                                            href='/profile'
                                        >
                                            Profile
                                        </DropdownItem>

                                        <DropdownItem divider />
                                        <DropdownItem
                                            onClick={() => this.logout()}
                                            href='/'
                                        >
                                            Logout
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                // </div>
                            )}
                        </Collapse>
                    </Container>
                </Navbar>
            </React.Fragment>
        );
    }
}
// }
export default NavbarPage;
