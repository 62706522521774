import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { BASE_API_URL } from "../utils/constants";
import { UserContext } from "../App";
import { useHistory, Link } from "react-router-dom";
import Deal from "../pages/Deals";
import "../assets/css/style.css";


import Navbar from "./Navbar/NavBar";

const Login = () => {

  const items = [
    // { id: 1, idnm: "home", navheading: "Home" },
    // { id: 3, idnm: "services", navheading: "Services" },
    // { id: 4, idnm: "pricing", navheading: "Pricing" },
    // { id: 5, idnm: "team", navheading: "Team" },
    // { id: 6, idnm: "clients", navheading: "Clients" },
    // { id: 7, idnm: "contact", navheading: "Contact" },
  ];

  const [navItems, setNavItems] = useState(items);
  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImgLight] = useState(false);
  const [navClass, setNavClass] = useState("");
  const [fixTop, setFixTop] = useState(true);

  const { state, dispatch } = useContext(UserContext);

  const { register, handleSubmit, errors } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [viewPwd, setViewPwd] = useState(false);


  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")



  let history = useHistory();
  const onSubmit = async (data) => {
    console.log(data);
    let userInfo = localStorage.getItem('loggedIn');
    if (confirmPassword !== newPassword) {
      alert("Password does not match")
      return false
    }
    userInfo = JSON.parse(userInfo)
    let criteria = {
      user_email: userInfo.user_email,
      user_password: password,
      newPassword: newPassword
    }

    console.log(userInfo, criteria);
    try {
      const response = await axios.post(`${BASE_API_URL}/forget`, criteria);
      //Handle Success


      //Handle Failure 
      dispatch({ type: "user", payload: true });
      history.push("/Deals");
      setSuccessMessage("User with the provided credentials found.");
      setErrorMessage("");
      setUserDetails(response.data);

    } catch (error) {
      // console.log(error);
      if (error.response) {
        console.log("error", error.response.data);
        setErrorMessage(error.response.data);
      }
      localStorage.setItem('loggedIn', false);
    }
  };


  useEffect(() => {
    console.log("Hey");
  }, [password])

  return (
    <div>
      <Navbar
        navItems={navItems}
        navClass={navClass}
        imglight={imglight}
        top={fixTop}
        firstName={userDetails.first_name}
        lastName={userDetails.last_name}
      />






      <Form className="input-form" onSubmit={handleSubmit(onSubmit)}>

        <div
          style={{ paddingBottom: "2.2rem", marginTop: "7.5rem" }}
          className="col-md-6 offset-md-3 multi-step-form"
        >
          {errorMessage ? (
            <p className="errorMsg login-error">{errorMessage}</p>
          ) : (
            <div>
              <p className="successMsg">{successMessage}</p>
              {/* {history.push('/Deals')} */}
              {/* {userDetails && (
              <div className="user-details">
                <p>Following are the user details:</p>
                <div>First name: {userDetails.first_name}</div>
                <div>Last name: {userDetails.last_name}</div>
                <div>Email: {userDetails.user_email}</div>
                <div>Country: {userDetails.country}</div>
                <div>State: {userDetails.state}</div>
                <div>City: {userDetails.city}</div>
              </div>
            )} */}
            </div>
          )}
          <h1 className="multi-step-heading">Change Password</h1>

          <div>
          <label className="m-2">Current Password</label>
            <input type="password" placeholder="Enter your OLD password" onKeyUp={(e => setPassword(e.target.value))} className=""/>

            <label className="m-2">New Password</label>
            <input type="password" placeholder="Enter your new password" onKeyUp={(e => setNewPassword(e.target.value))} />

            <label className="m-2">Confirm Password</label>
            <input type="password" placeholder="Confirm your new password" onKeyUp={(e => setConfirmPassword(e.target.value))} />
            <div
            style={{
              position: "absolute",
              right: "1.6rem",
              top: "22.8rem",
              fontSize: "15px",
              cursor: "pointer",
            }}
          >
            <i
              onClick={() => {
                setViewPwd(!viewPwd);
              }}
              className={`fa ${viewPwd ? "fa-eye-slash" : "fa-eye"}`}
            ></i>
          </div>

            <div style={{ textAlign: "center" }}>
            <Button variant="primary" type="submit"  onClick={() => { onSubmit() }} className="m-2">
              Change Password
            </Button>
          </div>
            {/* <Button onClick={() => { onSubmit() }}>Change Password</Button> */}

          </div>


          {/* <Form.Group controlId="first_name">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              name="old_password"
              placeholder="Enter your old password "
              {...register('user_email', { required: "First name is required.", message: "First name should contain only characters." })}
              className={`${errors ? "input-error" : ""}`}
            />
            {errors && (
              <p className="errorMsg">{errors}</p>
            )}
          </Form.Group>
          <Form.Group controlId="first_name">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              name="new_password"
              placeholder="Enter your old password "
              {...register('user_email', { required: "First name is required.", message: "First name should contain only characters." })}
              className={`${errors ? "input-error" : ""}`}
            />

            {errors && (
              <p className="errorMsg">{errors}</p>
            )}
          </Form.Group>




          <Form.Group controlId="password">
            <Form.Label> Confirm Password</Form.Label>
            <Form.Control

              type={viewPwd ? "text" : "password"}
              name="user_password"
              placeholder="Enter your password"
              {...register("user_password", {
                required: "First name is required.",
                message: "First name should contain only characters.",
              })}
              className={`${errors ? "input-error" : ""}`}
            />
            <div
              style={{
                position: "absolute",
                right: "1.4rem",
                top: "13.4rem",
                fontSize: "15px",
                cursor: "pointer",
              }}
            >
              <i
                onClick={() => {
                  setViewPwd(!viewPwd);
                }}
                className={`fa ${viewPwd ? "fa-eye-slash" : "fa-eye"}`}
              ></i>
            </div>
            {errors && <p className="errorMsg">{errors}</p>}
          </Form.Group>



          <div style={{ textAlign: "center" }}>
            <Button variant="primary" type="submit" >
              Change Password
            </Button>
          </div> */}


        </div>
      </Form>
    </div>

  );
};

export default Login;
