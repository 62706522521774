import React, { Component, useContext, useState } from 'react';
import {
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Button,
  Input,
  Label,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { BASE_API_URL } from '../utils/constants';
import { UserContext } from '../App';
import '../assets/css/style.css';

import Spinner from './Spinner';
import Navbar from '../component/Navbar/NavBar';

import Feature4 from '../assets/images/features/img-4.png';
import LogoDark from '../assets/images/logo-dark.png';
import { FormControl } from 'react-bootstrap';

const Login = () => {
  const items = [
    // { id: 1, idnm: "home", navheading: "Home" },
    // { id: 3, idnm: "services", navheading: "Services" },
    // { id: 4, idnm: "pricing", navheading: "Pricing" },
    // { id: 5, idnm: "team", navheading: "Team" },
    // { id: 6, idnm: "clients", navheading: "Clients" },
    // { id: 7, idnm: "contact", navheading: "Contact" },
  ];

  const [navItems, setNavItems] = useState(items);
  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImgLight] = useState(false);
  const [navClass, setNavClass] = useState('');
  const [fixTop, setFixTop] = useState(true);

  const { state, dispatch } = useContext(UserContext);

  const { register, handleSubmit, errors } = useForm();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userDetails, setUserDetails] = useState('');
  const [viewPwd, setViewPwd] = useState(false);
  let history = useHistory();
  const onSubmit = async (data) => {
    console.log(data);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/login`,
        data
      );
      dispatch({ type: 'user', payload: true });
      history.push('/Deals');
      window.location.reload();
      setSuccessMessage('User with the provided credentials found.');
      localStorage.setItem('loggedIn', JSON.stringify(response.data));
      setErrorMessage('');
      setUserDetails(response.data);
    } catch (error) {
      // console.log(error);
      if (error.response) {
        console.log('error', error.response.data);
        setErrorMessage(error.response.data);
      }
      localStorage.setItem('loggedIn', false);
    }
  };

  return (
    <React.Fragment>
      <Navbar
        navItems={navItems}
        navClass={navClass}
        imglight={imglight}
        top={fixTop}
        firstName={userDetails.first_name}
        lastName={userDetails.last_name}
      />

      <section className="vh-100" style={{ marginTop: '100px' }}>
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="login-box">
                    <Row className="align-items-center no-gutters">
                      <Col lg={6}>
                        <div className="bg-light">
                          <div className="row justify-content-center">
                            <div className="col-lg-10">
                              <div className="home-img login-img text-center d-none d-lg-inline-block">
                                <div className="animation-2"></div>
                                <div className="animation-3"></div>
                                <img
                                  src={Feature4}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Row className="justify-content-center">
                          <Col lg={11}>
                            <div className="p-4">
                              <div className="text-center mt-3">
                                {/* <Link to="/"><img src={LogoDark} alt=""
                                    height="22" /></Link> */}
                                <h1 className=" mt-3">Login</h1>
                              </div>
                              <div className="p-3 custom-form">
                                <Form
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div>
                                    {errorMessage ? (
                                      <p className="errorMsg login-error">
                                        {errorMessage}
                                      </p>
                                    ) : (
                                      <div>
                                        <p className="successMsg">
                                          {successMessage}
                                        </p>
                                        {/* {history.push('/Deals')} */}
                                        {/* {userDetails && (
              <div className="user-details">
                <p>Following are the user details:</p>
                <div>First name: {userDetails.first_name}</div>
                <div>Last name: {userDetails.last_name}</div>
                <div>Email: {userDetails.user_email}</div>
                <div>Country: {userDetails.country}</div>
                <div>State: {userDetails.state}</div>
                <div>City: {userDetails.city}</div>
              </div>
            )} */}
                                      </div>
                                    )}

                                    <FormGroup controlId="first_name">
                                      <Label>Email</Label>
                                      {/* <Input type="text" className="form-control" id="username"
                                        placeholder="Enter username" /> */}
                                      <FormControl
                                        type="email"
                                        name="user_email"
                                        placeholder="Enter registered Email "
                                        {...register('user_email', {
                                          required:
                                            'First name is required.',
                                          message:
                                            'First name should contain only characters.',
                                        })}
                                        className={`${
                                          errors ? 'input-error' : ''
                                        }`}
                                      />
                                      {errors && (
                                        <p className="errorMsg">
                                          {errors}
                                        </p>
                                      )}
                                    </FormGroup>
                                    <FormGroup>
                                      <Label for="userpassword">
                                        Password
                                      </Label>
                                      {/* <Input type="password" className="form-control"
                                        id="userpassword" placeholder="Enter password" /> */}

                                      <FormControl
                                        type={
                                          viewPwd
                                            ? 'text'
                                            : 'password'
                                        }
                                        name="user_password"
                                        placeholder="Enter your password"
                                        {...register(
                                          'user_password',
                                          {
                                            required:
                                              'First name is required.',
                                            message:
                                              'First name should contain only characters.',
                                          }
                                        )}
                                        className={`${
                                          errors ? 'input-error' : ''
                                        }`}
                                      />
                                      <div
                                        style={{
                                          position: 'absolute',
                                          right: '4.1rem',
                                          top: '17.5rem',
                                          fontSize: '15px',
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <i
                                          onClick={() => {
                                            setViewPwd(!viewPwd);
                                          }}
                                          className={`fa ${
                                            viewPwd
                                              ? 'fa-eye-slash'
                                              : 'fa-eye'
                                          }`}
                                        ></i>
                                      </div>
                                      {errors && (
                                        <p className="errorMsg">
                                          {errors}
                                        </p>
                                      )}
                                    </FormGroup>

                                    {/*         
                                    <div className="custom-control custom-checkbox">
                                      <Input type="checkbox" className="custom-control-input"
                                        id="customControlInline" />
                                      <Label className="custom-control-label"
                                        for="customControlInline">Remember me</Label>
                                    </div>
                                    <div className="mt-3">
                                      <Button color="primary" className="btn btn-primary btn-block" block>Log In</Button>{" "}
                                    </div>
                                    <div className="mt-4 pt-1 mb-0 text-center">
                                      <Link to="/ForgotPassword" className="text-dark"><i
                                        className="mdi mdi-lock"></i> Forgot your
                                                                    password?</Link>
                                    </div>
                                    </div>
                                  </Form> */}
                                    <div
                                      style={{ textAlign: 'center' }}
                                    >
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        style={{
                                          background: '#202054',
                                        }}
                                      >
                                        Login
                                      </Button>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          fontSize: '15px',
                                          textAlign: 'center',
                                          marginTop: '25px',
                                        }}
                                      >
                                        Don't have an account yet?{' '}
                                        <span>
                                          <Link
                                            to="/SignUp"
                                            style={{
                                              textDecoration: 'none',
                                              color: '#202054',
                                            }}
                                          >
                                            <strong>Sign Up</strong>
                                          </Link>
                                        </span>
                                      </p>
                                      <p
                                        style={{
                                          fontSize: '15px',
                                          textAlign: 'center',
                                          marginTop: '25px',
                                        }}
                                      >
                                        Forgot Password?{' '}
                                        <span>
                                          <Link
                                            to="/changePwd"
                                            style={{
                                              textDecoration: 'none',
                                              color: '#202054',
                                            }}
                                          >
                                            <strong>
                                              Click Here
                                            </strong>
                                          </Link>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </Form>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Login;
