import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Modal Video
import ModalVideo from "react-modal-video";

class Section extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
 
  openModal () {
    this.setState({isOpen: true})
  }

  render() {
    var temsettings = {
      autoplay: true,
      dots: true,
      speed: 300,
      infinite: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <React.Fragment>
        <section className="bg-home" id="home" style={{ backgroundColor: '#ececec', padding: '200px 0px 200px 0px' }}>
          <div className="home-center" >
            <div className="home-desc-center">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={9}>
                    <div className="main-slider home-content text-center">
                      <ul className="slides">
                        <Slider {...temsettings}>
                          <li>
                            <h3 className="home-title">Invest Faith as well as Money</h3>
                            <p className="text-muted f-18 mt-3">Help startups thrive by investing just Rs. 5000.<br /> Get Equity and build more connections from the startup community.</p>
                            <div className="mt-4 pt-3">
                              <Link to="#" className="btn btn-primary mr-3">Contact Us</Link>
                              <Link to="#" className="video-play-icon text-dark" onClick={this.openModal}> <i className="mdi mdi-play-circle-outline text-dark mr-2"></i>Watch Intro Video</Link>
                            </div>
                          </li>
                          <li>
                            <h3 className="home-title">Something new is awaiting you</h3>
                            <p className="text-muted f-18 mt-3">Let the community kickstart your venture. Fund your startup without failure.<br /> Apply now and wait for the community’s magic to work.</p>
                            <div className="mt-4 pt-3">
                              <Link to="#" className="btn btn-primary mr-3">Contact Us</Link>
                              <Link to="#" className="video-play-icon text-dark" onClick={this.openModal}> <i className="mdi mdi-play-circle-outline text-dark mr-2"></i>Watch Intro Video</Link>
                            </div>
                          </li>
                          <li>
                            <h3 className="home-title">Get funded by a crowd <br />who beleives in you.</h3>
                            <p className="text-muted f-18 mt-3">Let the community kickstart your venture.<br /> Fund your startup without failure. Apply now and wait for the community’s magic to work</p>
                            <div className="mt-4 pt-3">
                              <Link to="#" className="btn btn-primary mr-3">Contact Us</Link>
                              <Link to="#" className="video-play-icon text-dark" onClick={this.openModal}> <i className="mdi mdi-play-circle-outline text-dark mr-2"></i>Watch Intro Video</Link>
                            </div>
                          </li>
                        </Slider>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
              <ModalVideo
                channel="youtube"
                isOpen={this.state.isOpen}
                videoId="09PACiS_wnI"
                onClose={() => this.setState({ isOpen: false })}
              />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;