import React, { Component, useEffect, useState } from 'react';
import {
  Container,
  FormGroup,
  Input,
  Label,
  Badge,
  Button,
} from 'reactstrap';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import HomeUrl from '../../assets/images/home-border.png';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

// import { BASE_API_URL } from "../utils/constants";
import { Link as Link2 } from 'react-scroll';
//for modal window
import './style.css';

import Progressbar from './Progress';

// Importing Section
import Navbar from '../../component/Navbar/NavBar';
import Clients from './Clients';
import Footer from '../../component/Footer/Footer';
import details from './Details';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Faq from 'react-faq-component';
import { Avatar } from '@material-ui/core';

const data = {
  title: 'FAQ',
  rows: [
    {
      title: 'What is bidwheelz and how did it start ? ',
      content: `Bidwheelz is India’s first live auction platform which provides end to end services. 
          It started in 2018 when we saw that the end customer is not actual price for its vehicle and the major profit is being captured by the middle men that is when BidWheelz was originated.`,
    },
    {
      title:
        'What are the growth plans and what Bidwheelz is aiming to be ?',
      content:
        "BidWheelz aims to be India's first ever Inventory free aggregator platform which provided the best price of the vehicle to both the parties through live and transparent auctioning.",
    },
    {
      title: 'why should one choose to invest in Bidwheelz ?',
      content: `BidWheelz is Indias first ever live auction platform with all end to end services so we can capture the market faster and change the way people buy and sell vehicles.`,
    },
  ],
};

// const styles = {
//   // bgColor: 'white',
//   titleTextColor: "blue",
//   rowTitleColor: "blue",
//   // rowContentColor: 'grey',
//   // arrowColor: "red",
// };

// const config = {
//   // animate: true,
//   // arrowIcon: "V",
//   // tabFocus: true
// };

// badge content for pitch deck
const badgeContent = [
  {
    id: '1',
    title: 'Problem',
    path: 'startuproblem',
  },
  {
    id: '2',
    title: 'Solution',
    path: 'startupsolution',
  },

  {
    id: '3',
    title: 'Product',
    path: 'startupproduct',
  },

  {
    id: '4',
    title: 'Traction',
    path: 'startuptraction',
  },

  { id: '5', title: 'Customers', path: 'startupcustomers' },
  {
    id: '6',
    title: 'Business Model',
    path: 'startupmodel',
  },
  {
    id: '7',
    title: 'Competition',
    path: 'startupcompetition',
  },

  {
    id: '8',
    title: ' Usage of funds',
    path: 'startupfundusage',
  },

  {
    id: '9',
    title: ' Vision',
    path: 'startupvision',
  },
  {
    id: '10',
    title: 'Exit',
    path: 'startupexit',
  },
];

const Content = (props) => {
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(totalAmount) {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const result = await axios.post(
      'https://bizzdateupjs.herokuapp.com/payOnline',
      { totalAmount: totalAmount }
    );

    if (!result) {
      alert('Server error. Are you online?');
      return;
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: 'rzp_live_bM1HglWh7fuTPR',
      amount: amount.toString(),
      currency: currency,
      // name : "",
      description: 'Investing Amount',
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post(
          'https://bizzdateupjs.herokuapp.com/payOnline',
          data
        );
        console.log(result.data);
      },

      theme: {
        color: 'black',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const { register, handleSubmit, reset } = useForm();
  // const [searchParams, setSearchParams] = useSearchParams();

  const Submit = async (data) => {
    if (data.amount < 5000) {
      Swal.fire({
        text: 'Minimum amount should be Rs.5000',
        width: 400,
        height: 400,
      });
    } else if (data.amount % 1000 != 0) {
      // Swal.fire("Amount should be a multiple of 1000")
      Swal.fire({
        text: 'Amount should be a multiple of 1000',
        width: 400,
        height: 400,
      });
    } else {
      console.log(data);
      const response = await axios.post(
        'https://bizzdateupjs.herokuapp.com/invest',
        data
      );
      console.log(response.data);

      Swal.fire({
        showDenyButton: true,
        confirmButtonText: 'Pay Now',
        denyButtonText: `Edit Details`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'sweet-alert-button',
          denyButton: 'sweet-alert-button',
        },
        html: `<div className = " w-96 mx-auto text-center mt-5 mb-3">
        <table id="table"  className="mx-auto text-center tablelayout">
        <thead className="headerlayout">
            <tr>
                <th>Particulars</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="width:50%">Investment Amount<br><div className="smalltext text-justify">(Inclusive of GST : 18%)<div className="hoverable"> <i className="bi bi-info-circle iconstyle" ></i><div className="infohover text-justify">As CSOP subscription Amount is considered as a revenue<br>item in the company's accounts, 18% GST is applicable </div></div>
                </td>
                

                <td style="width:50%">&#8377 ${data.amount}</td>
            </tr>
            <tr>
                <td style="width:50%">Convenience Fee (2%)</td>
                <td style="width:50%"> &#8377 ${response.data.convenienceFees}</td>
               
            </tr>
            <tr>
                <td style="width:50%">GST (18%) </td>
                <td style="width:50%">&#8377 ${response.data.gst}</td>
                
            </tr>
            <tr>
                <td style="width:50%">TDS (10%) </td>
                <td style="width:50%">- &#8377 ${response.data.tds}</td>
                
            </tr>
            <tr>
                <td style="width:50%">Total </td>
                <td style="width:50%">&#8377  ${response.data.totalAmount}</td>
                
            </tr>
</tbody>
</table>

<div className="mt-4 fontbold">
<span className="totalamount">
AMOUNT TO BE PAID
</span> : &#8377 ${response.data.totalAmount}
</div>

        <hr>
        
        </div>`,
        button: 'Confirm!',
      }).then((result) => {
        if (result.isConfirmed) {
          displayRazorpay(response.data.totalAmount);
          setModalShow(false);
          reset();
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
    }
  };

  const startup = details.find((st) => st.id === props.id);
  function MyVerticallyCenteredModal(props) {
    const [amount, setAmount] = useState(0);

    const handleAmount = (e, amt) => {
      e.preventDefault();
      let total = amount + amt;
      if (total >= 0) {
        setAmount(total);
        document.getElementById('amount').value = total;
      }
    };

    useEffect(() => {
      console.log(amount);
    });

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="titlestyle"
          >
            Enter Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(Submit)}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="productId">
                  <Form.Label>Product Id : </Form.Label>
                  <Form.Control
                    type="number"
                    value={startup.id}
                    placeholder="Product Id"
                    name="productId"
                    {...register('productId', {
                      required: true,
                    })}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="productName">
                  <Form.Label>Product Name : </Form.Label>
                  <Form.Control
                    type="text"
                    value={startup.title}
                    placeholder="Product Name"
                    name="productName"
                    {...register('productName', {
                      required: true,
                    })}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="amount">
                  <Form.Label>Amount : </Form.Label>
                  <Form.Control
                    className="inputstyle"
                    type="number"
                    // id='amount'
                    // value='5000'
                    placeholder="Enter amount"
                    name="amount"
                    {...register('amount', {
                      required: true,
                    })}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Modal.Footer>
              <Button
                className="btn btn-block btn-rounded submitbtn"
                type="submit"
              >
                SUBMIT
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
  // const items = [
  //   { id: 1, idnm: "home", navheading: "Home" },
  //   { id: 3, idnm: "services", navheading: "Services" },
  //   { id: 4, idnm: "pricing", navheading: "Pricing" },
  //   { id: 5, idnm: "team", navheading: "Team" },
  //   { id: 6, idnm: "clients", navheading: "Clients" },
  //   { id: 7, idnm: "contact", navheading: "Contact" },
  // ];

  // const [navItems, setNavItems] = useState(items);
  // const [pos, setPos] = useState(document.documentElement.scrollTop);
  // const [imglight, setImgLight] = useState(false);
  // const [navClass, setNavClass] = useState("");
  // const [fixTop, setFixTop] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  //number of pages in PDF
  const [numPages, setNumPages] = useState(null);

  const [scale, setScale] = useState(1.0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // const ID = '1';
  // const startup = details.find((st) => st.id === props.id);
  return (
    <React.Fragment>
      <section
        className="section"
        style={{ padding: '30px 0px 40px 0px' }}
        id="services"
      >
        <Container>
          <Row className="align-items-center mt-5 pt-4" id="counter">
            <Col lg={6}>
              <div className="pr-4 mt-4">
                <h3>{startup.title}</h3>

                <p className="text-uppercase">
                  <div>
                    <Badge
                      style={{
                        fontSize: '17px',
                        marginRight: '10px',
                        backgroundColor: '#202054',
                      }}
                    >
                      {startup.tag[0]}
                    </Badge>
                    <Badge
                      style={{
                        fontSize: '17px',
                        marginRight: '10px',
                        backgroundColor: '#202054',
                      }}
                    >
                      {startup.tag[1]}
                    </Badge>
                  </div>
                </p>
                <p className="text-muted mt-3">
                  {startup.description}
                </p>
                <Progressbar
                  bgcolor="#202054"
                  progress="5"
                  height={10}
                />
                <Progressbar animated now={45} />
                <div className="mt-4 pt-1">
                  <div className="team-social mt-4 pt-2">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Link
                          onClick={() => setModalShow(true)}
                          className="btn btn-outline-primary invest_button"
                          style={{
                            fontSize: '17px',
                            marginRight: '10px',
                          }}
                        >
                          INVEST
                        </Link>

                        <>
                          <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                        </>
                      </li>

                      <li className="list-inline-item">
                        <a
                          href={startup.facebook}
                          target="_blank"
                          className="text-reset"
                        >
                          <i className="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={startup.twitter}
                          target="_blank"
                          className="text-reset"
                        >
                          <i className="mdi mdi-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={startup.instagram}
                          target="_blank"
                          className="text-reset"
                        >
                          <i className="mdi mdi-instagram"></i>
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                      <Link to="#" className="text-reset"><i className="mdi mdi-pinterest"></i></Link>
                    </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={5}
              className="offset-lg-1"
              style={{ padding: '50px 0px 30px 0px' }}
            >
              <ReactPlayer
                width="100%"
                height="300px"
                controls="true"
                url={startup.youtubelink}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section></section>

      <section
        className="section"
        id="pricing"
        style={{ padding: '0px 0px 30px 0px' }}
      >
        <Container>
          {/* ============================================================== */}
          {/* <Row className=' pt-4'>
                        <Col sm='12'>
                            <div className='pricing-box mt-4'>
                                <div className='d-flex justify-content-start'>
                                    <i className={'h1 mdi ' + startup.icon}></i>

                                    <h4
                                        className='f-30 text-primary'
                                        style={{
                                            marginTop: '5px',
                                            marginLeft: '15px',
                                        }}
                                    >
                                        Team
                                    </h4>
                                </div>
                                <div className='d-flex justify-content-start'>
                                    <img
                                        src={Nakul}
                                        style={{
                                            borderRadius: '50%',
                                            maxWidth: '40px',
                                        }}
                                    />
                                    <h4
                                        className='f-20'
                                        style={{
                                            marginTop: '8px',
                                            marginLeft: '15px',
                                        }}
                                    >
                                        {startup.foundername}
                                    </h4>
                                </div>
                                <p
                                    className='mb-2 f-18'
                                    style={{ marginLeft: '3vw' }}
                                >
                                    {startup.foundernamerole}
                                </p>

                                <p
                                    className='mb-2 f-18'
                                    style={{ marginLeft: '3vw' }}
                                >
                                    {startup.founderdesc}
                                </p>
                                <div className='d-flex justify-content-start'>
                                    <img
                                        src={Sameer}
                                        style={{
                                            borderRadius: '50%',
                                            maxWidth: '40px',
                                        }}
                                    />
                                    <h4
                                        className='f-20'
                                        style={{
                                            marginTop: '8px',
                                            marginLeft: '15px',
                                        }}
                                    >
                                        {startup.foundername1}
                                    </h4>
                                </div>
                                <p
                                    className='mb-2 f-18 '
                                    style={{ marginLeft: '3vw' }}
                                >
                                    {startup.foundernamerole1}
                                </p>

                                <p
                                    className='mb-2 f-18'
                                    style={{ marginLeft: '3vw' }}
                                >
                                    {startup.founderdesc1}
                                </p> */}

          {/* <div className='team-social mt-4 pt-2'>
                                    <ul className='list-inline mb-0'>
                                        <li className='list-inline-item'>
                                            <Link
                                                to='{item.ins}'
                                                className='text-reset'
                                            >
                                                <i className='mdi mdi-facebook'></i>
                                            </Link>
                                        </li>
                                        <li className='list-inline-item'>
                                            <Link to='#' className='text-reset'>
                                                <i className='mdi mdi-twitter'></i>
                                            </Link>
                                        </li>
                                        <li className='list-inline-item'>
                                            <Link to='#' className='text-reset'>
                                                <i className='mdi mdi-google'></i>
                                            </Link>
                                        </li>
                                        <li className='list-inline-item'>
                                            <Link to='#' className='text-reset'>
                                                <i className='mdi mdi-pinterest'></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
          {/* <div className="mt-4 pt-3">
                      <Link to="#" className="btn btn-primary btn-rounded">Purchase Now</Link>
                    </div> */}
          {/* </div>
                        </Col>
                    </Row> */}
          {/* =============================Start======================================= */}
          <Row className="pt-4">
            <Col lg="12">
              <div className="pricing-box mt-4 ">
                <div className="d-flex justify-content-start">
                  <i className={'h1 mdi ' + startup.icon}></i>

                  <h4
                    className="f-30 text-primary"
                    style={{
                      marginTop: '5px',
                      marginLeft: '15px',
                    }}
                  >
                    {' '}
                    Team{' '}
                  </h4>
                </div>
                <Row>
                  <Col md="4">
                    <div className=" card align-items-center  shadow  bg_gradient h-100">
                      <img
                        src={startup.founderimg}
                        className="cardimg rounded-circle border border-warning shadow p-3 mb-3 bg-white rounded mt-3"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center mb-0">
                          {startup.foundername}
                        </h5>
                        <h6 className=" text-center mt-0 text-muted">
                          {startup.foundernamerole}
                        </h6>
                        {/* <div className='mb-3 font-monospace'>
                                                    {startup.founderdesc}
                                                </div> */}
                        <div className="text-center theme_color pt-2 border-top  border-warning">
                          <a href={startup.founderlinkedin}>
                            <i
                              className="fa fa-linkedin-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a href={startup.founderfacebook}>
                            <i
                              className="fa fa-facebook-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a href={startup.foundertwitter}>
                            <i
                              className="fa fa-twitter-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md="4">
                    <div className="card align-items-center shadow  bg_gradient h-100">
                      <img
                        src={startup.founderimg1}
                        className="cardimg rounded-circle border border-warning shadow p-3 mb-3 bg-white rounded mt-3"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center mb-0">
                          {startup.foundername1}
                        </h5>
                        <h6 className=" text-center mt-0 text-muted">
                          {startup.foundernamerole1}
                        </h6>
                        {/* <div className='mb-3 font-monospace'>
                                                    {startup.founderdesc}
                                                </div> */}
                        <div className="text-center theme_color pt-2 border-top  border-warning">
                          <a href={startup.founderlinkedin1}>
                            <i
                              className="fa fa-linkedin-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a href={startup.founderfacebook1}>
                            <i
                              className="fa fa-facebook-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a href={startup.foundertwitter1}>
                            <i
                              className="fa fa-twitter-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="card align-items-center  shadow  bg_gradient h-100">
                      <img
                        src={startup.founderimg2}
                        className="cardimg rounded-circle border border-warning shadow p-3 mb-3 bg-white rounded  mt-3"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center mb-0">
                          {startup.foundername2}
                        </h5>
                        <h6 className=" text-center mt-0 text-muted">
                          {startup.foundernamerole2}
                        </h6>
                        {/* <div className='mb-3 font-monospace'>
                                                    {startup.founderdesc}
                                                </div> */}
                        <div className="text-center theme_color pt-2 border-top  border-warning">
                          <a href={startup.founderlinkedin2}>
                            <i
                              className="fa fa-linkedin-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a href={startup.founderfacebook2}>
                            <i
                              className="fa fa-facebook-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a href={startup.foundertwitter2}>
                            <i
                              className="fa fa-twitter-square fa-lg m-2"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/* ================================================================ */}

          {/* backed up by */}
          <Row className="pt-4">
            <Col lg="12">
              <div className="pricing-box mt-4 ">
                <div className="d-flex justify-content-start">
                  <i className={'h1 mdi ' + startup.icon}></i>

                  <h4
                    className="f-30 text-primary"
                    style={{
                      marginTop: '5px',
                      marginLeft: '15px',
                    }}
                  >
                    {' '}
                    Backed by the Best{' '}
                  </h4>
                </div>
                <Row>
                  <Col md="3">
                    <div className=" card align-items-center  shadow   h-100">
                      <img
                        src={startup.founderimg}
                        className="cardimg1 rounded-circle border border-warning shadow  mb-3 bg-white rounded mt-3"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center mb-0">
                          {startup.foundername}
                        </h5>
                        {/* <h6 className=" text-center mt-0 text-muted">
                          {startup.foundernamerole}
                        </h6> */}
                        <h6 className=" text-center m-1 text-muted">
                          Hello this is a two liner description about
                          the person in the card.
                        </h6>
                        {/* <div className='mb-3 font-monospace'>
                                                    {startup.founderdesc}
                                                </div> */}
                      </div>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className=" card align-items-center  shadow   h-100">
                      <img
                        src={startup.founderimg}
                        className="cardimg1 rounded-circle border border-warning shadow  mb-3 bg-white rounded mt-3"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center mb-0">
                          {startup.foundername}
                        </h5>
                        {/* <h6 className=" text-center mt-0 text-muted">
                          {startup.foundernamerole}
                        </h6> */}
                        <h6 className=" text-center m-1 text-muted">
                          Hello this is a two liner description about
                          the person in the card.
                        </h6>
                        {/* <div className='mb-3 font-monospace'>
                                                    {startup.founderdesc}
                                                </div> */}
                      </div>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className=" card align-items-center  shadow   h-100">
                      <img
                        src={startup.founderimg}
                        className="cardimg1 rounded-circle border border-warning shadow  mb-3 bg-white rounded mt-3"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center mb-0">
                          {startup.foundername}
                        </h5>
                        {/* <h6 className=" text-center mt-0 text-muted">
                          {startup.foundernamerole}
                        </h6> */}
                        <h6 className=" text-center m-1 text-muted">
                          Hello this is a two liner description about
                          the person in the card.
                        </h6>
                        {/* <div className='mb-3 font-monospace'>
                                                    {startup.founderdesc}
                                                </div> */}
                      </div>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className=" card align-items-center  shadow   h-100">
                      <img
                        src={startup.founderimg}
                        className="cardimg1 rounded-circle border border-warning shadow  mb-3 bg-white rounded mt-3"
                        alt="..."
                      />
                      <div className="card-body">
                        <h5 className="card-title text-center mb-0">
                          {startup.foundername}
                        </h5>
                        {/* <h6 className=" text-center mt-0 text-muted">
                          {startup.foundernamerole}
                        </h6> */}
                        <h6 className=" text-center m-1 text-muted">
                          Hello this is a two liner description about
                          the person in the card.
                        </h6>
                        {/* <div className='mb-3 font-monospace'>
                                                    {startup.founderdesc}
                                                </div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* ************************************************** */}
          <Row className=" pt-4">
            <Col lg="12">
              <div className="pricing-box mt-4">
                <Faq
                  data={data}
                  styles={{
                    bgColor: 'white',
                    titleTextColor: '#202054',
                    rowTitleColor: '#1e3547',
                    rowTitleTextSize: 'large',
                    rowContentColor: '#48484a',
                    rowContentTextSize: '16px',
                    rowContentPaddingTop: '10px',
                    rowContentPaddingBottom: '10px',
                    rowContentPaddingLeft: '50px',
                    rowContentPaddingRight: '150px',
                    arrowColor: '#202054',
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row className="pt-4 align-items-centre ">
            <Col lg="6">
              <div className="pricing-box mt-4">
                <i className={'h1 mdi ' + startup.icon2}></i>

                <h4 className="f-20 text-primary">
                  Highlights / Key Features
                </h4>
                <p className="mb-2 f-18"></p>

                {/* <h4 className="f-20">efefefef</h4> */}

                <div className="mt-4 pt-2">
                  <p className="mb-2">
                    <i
                      className={
                        'mdi ' + startup.green + ' f-18 mr-2'
                      }
                    ></i>
                    <b>{startup.keyfeature1}</b>
                  </p>
                  <p className="mb-2">
                    <i
                      className={
                        'mdi ' + startup.green + ' f-18 mr-2'
                      }
                    ></i>
                    <b>{startup.keyfeature2}</b>
                  </p>
                  <p className="mb-2">
                    <i
                      className={
                        'mdi ' + startup.green + ' f-18 mr-2'
                      }
                    ></i>
                    <b>{startup.keyfeature3}</b>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="pricing-box mt-4">
                <div className="pricing-plan mt-4 pt-2">
                  <p className="f-18 mr-2 text-muted">
                    Min Investment
                    <p className="f-18 mr-2">
                      <b>{startup.minimuminvest}</b>
                    </p>
                  </p>
                  <p className="f-18 mr-2 text-muted">
                    Valuation
                    <p className="mb-2">
                      <b>{startup.valuation}</b>
                    </p>
                  </p>
                  <p className="f-18 mr-2 text-muted">
                    Target
                    <p className="mb-2">
                      <b>{startup.target}</b>
                    </p>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <div className="pricing-box mt-4">
            <Row className="pt-4">
              <Col lg="12" className="m-2">
                <div className="pricing-badge">
                  <span className="badge">Important</span>{' '}
                </div>
                <i className={'h1 mdi ' + startup.icon3}></i>

                <h4 className="f-20 text-primary">Due Diligence</h4>
                <p className="mt-4 pt-2 text-muted">
                  A due diligence report is a document prepared by an
                  independent third-party due diligence team that
                  covers financial information, compliance, major
                  risk, and a lot more information.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mt-4 pt-2">
                  <p className="mb-2">
                    <i
                      className={'mdi ' + startup.due + ' f-18 mr-2'}
                    ></i>{' '}
                    Company Due Dilligence
                  </p>
                  <p className="mb-2">
                    <i
                      className={'mdi ' + startup.due + ' f-18 mr-2'}
                    ></i>{' '}
                    Company Certificates
                  </p>
                </div>
              </Col>
              <Col lg="6">
                <div className="mt-4 pt-3" style={{ float: 'right' }}>
                  <a
                    href={startup.file}
                    download
                    className="btn btn-primary btn-rounded "
                  >
                    Download
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="title-box text-center">
                <h3 className="title-heading mt-4">Pitch Deck</h3>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {startup.pitch ? (
        <>
          <div
            id="pdfDocument"
            style={{
              marginBottom: '10rem',
              marginTop: '3rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Document
              file={startup.pitch}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  onLoadSuccess={(page) => {
                    setScale(
                      document.querySelector('#pdfDocument')
                        .clientWidth /
                        (page.originalWidth + page.originalWidth / 10)
                    );
                  }}
                  scale={scale}
                  pageNumber={index + 1}
                  style={{ padding: 0 }}
                />
              ))}
            </Document>
          </div>
        </>
      ) : (
        <>
          <Container
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {badgeContent.map((item, id) => {
              return (
                <>
                  <Badge
                    key={id}
                    style={{
                      fontSize: '17px',
                      margin: '7px',
                      backgroundColor: '#252552',
                    }}
                  >
                    <Link2
                      activeClass="active"
                      to={item.path}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      className="linkstyle"
                    >
                      {item.title}
                    </Link2>
                  </Badge>
                </>
              );
            })}
          </Container>

          {/* <Container>
            <Badge href='/startuproblem' color="info" style={{fontSize: '17px', marginRight: '10px'}}>Problem</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Solution</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Product</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Traction</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Customers</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Business Model</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Competition</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Usage of funds</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Vision</Badge>
            <Badge color="info" style={{fontSize: '17px', marginRight: '10px'}}>Exit</Badge>
   
   </Container>         */}

          <section className="section" id="contact">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="title-box text-center">
                    <h3 className="title-heading mt-4">Pitch Deck</h3>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    <h3
                      className="title-heading mt-4"
                      id="startuproblem"
                    >
                      Problem
                    </h3>
                    <p className="text-muted f-17 mt-3">
                      {startup.problem}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>
              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupsolution"
                    >
                      Solution
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.solution}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>

              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupproduct"
                    >
                      Product
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.product}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>

              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startuptraction"
                    >
                      Traction
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.traction}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>

              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupcustomers"
                    >
                      Customers
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.customers}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>
              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupmodel"
                    >
                      Business Model
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.businessmodel}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>

              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupcompetition"
                    >
                      Competition
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.competition}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>

              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupfundusage"
                    >
                      Usage Of Funds
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.fundsusage}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>

              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupvision"
                    >
                      Vision
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.vision}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>

              <Row>
                {' '}
                <Col lg={12}>
                  {' '}
                  <div className="title-box text-left">
                    {' '}
                    <h3
                      className="title-heading mt-4"
                      id="startupexit"
                    >
                      Exit
                    </h3>{' '}
                    <p className="text-muted f-17 mt-3">
                      {startup.exit}
                    </p>{' '}
                    <img
                      src={HomeUrl}
                      height="15"
                      className="mt-3"
                      alt=""
                    />{' '}
                  </div>{' '}
                </Col>{' '}
              </Row>
            </Container>
          </section>
        </>
      )}
    </React.Fragment>
  );
};

const Startups = ({ match }) => {
  const items = [
    // { id: 1, idnm: "home", navheading: "Home" },
    // { id: 3, idnm: "services", navheading: "Services" },
    // { id: 4, idnm: "pricing", navheading: "Pricing" },
    // { id: 5, idnm: "team", navheading: "Team" },
    // { id: 6, idnm: "clients", navheading: "Clients" },
    // { id: 7, idnm: "contact", navheading: "Contact" },
  ];

  const [navItems, setNavItems] = useState(items);
  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImgLight] = useState(false);
  const [navClass, setNavClass] = useState('');
  const [fixTop, setFixTop] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);

    return () => {
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  });

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 100) {
      setNavClass('nav-sticky');
      setImgLight(true);
    } else {
      setNavClass('');
      setImgLight(false);
    }
  };

  return (
    <React.Fragment>
      <Navbar
        navItems={navItems}
        navClass={navClass}
        imglight={imglight}
        top={fixTop}
      />
      <Content id={match.params.id} />

      {/* Importing Clients */}
      {/* <Clients /> */}
      {/* Importing Footer */}
      <Footer />
    </React.Fragment>
  );
};
export default Startups;
