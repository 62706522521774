import React, { Component } from 'react';

import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import HomeUrl from '../assets/images/home-border.png';
import Feature from '../assets/images/features/img-3.png';
import User from '../assets/images/users/profile.png';
import { BASE_API_URL } from '../utils/constants';
import axios from 'axios';

import Button from '@material-ui/core/Button';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMenu: false,
            check: localStorage.getItem('loggedIn') !== null ? true : false,
            user: JSON.parse(localStorage.getItem('loggedIn')),
            reload: true,
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState({ isOpenMenu: !this.state.isOpenMenu });
    };

    // componentDidUpdate(){
    //   this.setState({check:localStorage.getItem('loggedIn')!==null?true:false});
    // }
    logout() {
        localStorage.clear();
        window.location.reload();
    }

    logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    handleUpdateImage = async (e) => {
        // console.log(this.state.user.imageAddress);
        // const image = e.target.files[0];
        const image = document.getElementById('contained-button-file').files[0];
        let imageFileName = '';
        if (image) {
            const fd = new FormData();
            fd.append('file', image, image.name);

            try {
                const res = await axios.post(`${BASE_API_URL}/upload`, fd);
                console.log(res.data);
                imageFileName = res.data;
            } catch (err) {
                console.log(err);
            }

            try {
                const data = await axios.post(`${BASE_API_URL}/updateImage`, {
                    imageAddress: imageFileName,
                    userEmail: this.state.user.user_email,
                });
                localStorage.clear();
                localStorage.setItem('loggedIn', JSON.stringify(data.data));
                console.log(data.data);
                window.location.reload();
            } catch (err) {
                alert(err);
            }
        } else {
            alert('No Image found!!!');
        }
    };

    render() {
        return (
            <React.Fragment>
                <section className='section' id='contact'>
                    <Container className='bg-box'>
                        <div className='main-body mt-5'>
                            <Row className='gutters-sm mt-5 h-100'>
                                <Col className='col-sm-4 mb-5'>
                                    <div className='card-deck h-100 '>
                                        <Col className='mt-1'>
                                            <div className='card-rounded mr-3 mb-4 bg-white'>
                                                <div className='card-body '>
                                                    <div class='d-flex flex-column align-items-center text-center'>
                                                        <img
                                                            // src={User}
                                                            src={
                                                                this.state.user
                                                                    ? `${BASE_API_URL}/image/${this.state.user.imageAddress}`
                                                                    : { User }
                                                            }
                                                            alt='Admin'
                                                            className='rounded-circle border border-dark shadow p-3 mb-3 bg-white rounded '
                                                            width='150'
                                                            height='150'
                                                        />

                                                        <input
                                                            type='file'
                                                            accept='image/*'
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                            id='contained-button-file'
                                                            onChange={
                                                                this
                                                                    .handleUpdateImage
                                                            }
                                                        />

                                                        <label htmlFor='contained-button-file'>
                                                            <Button
                                                                variant='contained'
                                                                style={{
                                                                    backgroundColor:
                                                                        '#202054',
                                                                    color: '#ffffff',
                                                                }}
                                                                component='span'
                                                            >
                                                                Change Profile
                                                            </Button>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='card-rounded mt-3 mr-3 bg-white'>
                                                <div className='card-body'>
                                                    <div class='d-flex flex-column align-items-center text-center'>
                                                        <div className=' mb-3'>
                                                            <h4>
                                                                {
                                                                    this.state
                                                                        .user
                                                                        ?.first_name
                                                                }{' '}
                                                                {
                                                                    this.state
                                                                        .user
                                                                        ?.last_name
                                                                }
                                                            </h4>
                                                        </div>

                                                        <div class=' mt-3 mb-1'>
                                                            <div className='btn btn-sm btn-soft-primary btn-round bg-white'>
                                                                <h4 className='text-muted '>
                                                                    Total
                                                                    Invested
                                                                </h4>
                                                                <h4 className='text-muted '>
                                                                    &#8377;
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col className='col-sm-8'>
                                    <div className='card-rounded mb-3 pt-3 bg-white'>
                                        <div className='card-body'>
                                            <Row>
                                                <Col className='col-sm-4'>
                                                    <h5 className='mb-0 ml-3 '>
                                                        Full Name
                                                    </h5>
                                                </Col>
                                                <Col class='col-sm-8'>
                                                    <h5 className='text-muted ml-3 overflow-hidden'>
                                                        {
                                                            this.state.user
                                                                ?.first_name
                                                        }{' '}
                                                        {
                                                            this.state.user
                                                                ?.last_name
                                                        }
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className='col-sm-4'>
                                                    <h5 className='mb-0 ml-3'>
                                                        Email
                                                    </h5>
                                                </Col>
                                                <Col class='col-sm-8 '>
                                                    <h5 className='text-muted ml-3 overflow-hidden'>
                                                        {
                                                            this.state.user
                                                                ?.user_email
                                                        }
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <hr />

                                            <Row>
                                                <Col className='col-sm-4'>
                                                    <h5 className='mb-0 ml-3'>
                                                        Phone
                                                    </h5>
                                                </Col>
                                                <Col class='col-sm-8 '>
                                                    <h5 className='text-muted ml-3 overflow-hidden'>
                                                        {this.state.user?.phone}
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className='col-sm-4'>
                                                    <h5 className='mb-0 ml-3 '>
                                                        Age
                                                    </h5>
                                                </Col>
                                                <Col class='col-sm-8 '>
                                                    <h5 className='text-muted ml-3 overflow-hidden'>
                                                        {this.state.user?.age}
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className='col-sm-4'>
                                                    <h5 className='mb-0 ml-3'>
                                                        Gender
                                                    </h5>
                                                </Col>
                                                <Col class='col-sm-8 '>
                                                    <h5 className='text-muted ml-3 overflow-hidden'>
                                                        {
                                                            this.state.user
                                                                ?.genders
                                                        }
                                                    </h5>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className='col-sm-4'>
                                                    <h5 className='mb-0 ml-3 '>
                                                        Aadhar Card
                                                    </h5>
                                                </Col>
                                                <Col class='col-sm-8 '>
                                                    <h5 className='text-muted ml-3 overflow-hidden'>
                                                        {
                                                            this.state.user
                                                                ?.aadhar
                                                        }{' '}
                                                        <i className='mdi mdi-checkbox-marked-circle'></i>
                                                    </h5>
                                                </Col>
                                            </Row>

                                            <hr />
                                            <Row>
                                                <Col className='col-sm-4'>
                                                    <h5 className='mb-0 ml-3'>
                                                        Pan Card
                                                    </h5>
                                                </Col>
                                                <Col class='col-sm-8 '>
                                                    <h5 className='text-muted ml-3 overflow-hidden'>
                                                        {this.state.user?.pan}{' '}
                                                        <i className='mdi mdi-checkbox-marked-circle'></i>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                                {/* <Col className="col-md-12">
                 <div className="card mb-3">
                      <div className="card-body">
                        
                        <hr/>
                        </div>
                        </div>
                 </Col> */}
                            </Row>
                        </div>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}
