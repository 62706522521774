import React, { Component } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";



import Slider from "react-slick";

import Img1 from '../../assets/images/startup/brewedleaf/logo.jpg';
import Img11 from '../../assets/images/startup/brewedleaf/thumbnail.jpg';

import Img2 from '../../assets/images/startup/bidwheelz/logo.jpg';
import Img22 from '../../assets/images/startup/bidwheelz/thumbnail.jpg';

import HomeUrl from '../../assets/images/home-border.png';

import Client1 from '../../assets/images/clients/1.png';
import Client2 from '../../assets/images/clients/2.png';
import Client3 from '../../assets/images/clients/3.png';
import Client4 from '../../assets/images/clients/4.png';

export default class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideitems: [
        
        {
          id: 100,
          img: Img22,
          name: 'Bid Wheelz',
          designation: 'Designer',
          logo: Img2,
          description: 'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.'
        },
        {
          id: 101,
          img: Img11,
          name: 'Brewed Leaf',
          designation: 'Designer',
          logo: Img1,
          description: 'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.'
        },
        
      ],
    };
  }
  render() {
//     return(

// <section class="light">
// 	<div class="container py-2">
	
// 		<article class="postcard light blue">
// 			<a class="postcard__img_link" href="#">
// 				<img class="postcard__img" src={Img22} alt="Bidwheelz img" />
// 			</a>
// 			<div class="postcard__text t-dark">
// 				<h1 class="postcard__title blue"><a href="#">Bid Wheelz</a></h1>
				
// 				<div class="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, fugiat asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus nemo accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam adipisci illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi, illum quos!</div>
// 				<ul class="postcard__tagbox">
// 					<li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
// 					<li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
// 					<li class="tag__item play blue">
// 						<a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
// 					</li>
// 				</ul>
// 			</div>
// 		</article>

//     <article class="postcard light blue">
// 			<a class="postcard__img_link" href="#">
// 				<img class="postcard__img" src={Img11} alt="Image Title" />
// 			</a>
// 			<div class="postcard__text t-dark">
// 				<h1 class="postcard__title blue"><a href="#">Podcast Title</a></h1>
// 				<div class="postcard__subtitle small">
// 					<time datetime="2020-05-25 12:00:00">
// 						<i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
// 					</time>
// 				</div>
// 				<div class="postcard__bar"></div>
// 				<div class="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, fugiat asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus nemo accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam adipisci illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi, illum quos!</div>
// 				<ul class="postcard__tagbox">
// 					<li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
// 					<li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
// 					<li class="tag__item play blue">
// 						<a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
// 					</li>
// 				</ul>
// 			</div>
// 		</article>



    
//     </div>
//     </section>
    
//     );

    // const clientslides = this.state.slideitems.map((slideitem, clientkey) => {
    //   return (
    //     <div className="carousel-item" key={slideitem.id} >
    //       <Row className="align-items-center">
            
    //         <Col lg={5}  >
              
    //           <div className="text-center mt-4" >
    //             <img src={slideitem.img} className="img-fluid" alt=""  />
    //           </div>
    //         </Col>
    //         <Col lg={6}>
            
    //           <div className="client-box mt-4">
    //             <Row>
    //           <Col xs={4} lg={3}>
    //                   <img src={slideitem.logo} className="logoImg img-fluid"/>
    //                 </Col>
    //                 <Col xs={4} lg={7} className="mt-3">
    //                 <h2 className="f-36">Bidwheelz </h2>
    //                   </Col></Row>
              
              
    //             <h5 className="f-18">Bidwheelz is a Marketplace for buying and selling resale vehicles through Transparent Live Bidding. </h5>
    //             <br></br>
    //             <Row className="d-flex">
    //                     <Col lg={4}>
    //                  <p className="text-muted fontsize">PERCENTAGE RAISED</p>
    //                  <span className="extrainfo">3 %</span>
    //                     </Col>
    //                     <Col lg={4} >
    //                     <p className="text-muted fontsize">CLOSES IN </p>
    //                  <span className="extrainfo">28 Days</span>
                        
    //                     </Col>
    //                     <Col lg={4}>

    //                     <p className="text-muted fontsize">BACKED UP</p>
    //                  <span className="extrainfo">--</span>
    //                     </Col>
    //                     <Link to="/startup/2" className="btn btn-outline-primary m-3" style={{color:'#ffffff',backgroundColor:'#202054'}}>Invest</Link>
    //                   </Row>
    //           </div>
    //         </Col>
            
    //       </Row>
      
    //     </div>
    //   );
    // });

    // const clientslides1 = this.state.slideitems.map((slideitem, clientkey) => {
    //   return (
    //     <div className="carousel-item" key={slideitem.id} >
    //       <Row className="align-items-center">
            
    //         <Col lg={5} >
              
    //           <div className="text-center mt-4" >
    //             <img src={slideitem.img} className="img-fluid" alt=""  />
    //           </div>
    //         </Col>
    //         <Col lg={6}>
            
    //           <div className="client-box mt-4">
    //             <Row>
    //           <Col xs={4} lg={5}>
    //                   <img src={slideitem.logo} className="logoImg img-fluid"/>
    //                 </Col>
    //                 <Col xs={4} lg={7} className="mt-3">
    //                 <h2 className="f-36">Brewed Leaf </h2>
    //                   </Col></Row>
              
              
    //             <h5 className="f-18">Brewed Ventures aim to deliver the freshest tea from the boutique tea gardens within 1 week. </h5>
    //             <br></br>
    //             <Row className="d-flex">
    //                     <Col lg={4}>
    //                  <p className="text-muted fontsize">PERCENTAGE RAISED</p>
    //                  <span className="extrainfo">-- %</span>
    //                     </Col>
    //                     <Col lg={4} >
    //                     <p className="text-muted fontsize">CLOSES IN </p>
    //                  <span className="extrainfo">-- Days</span>
                        
    //                     </Col>
    //                     <Col lg={4}>

    //                     <p className="text-muted fontsize">BACKED UP</p>
    //                  <span className="extrainfo">---</span>
    //                     </Col>
    //                     <Link to="/startup/1" className="btn btn-outline-primary m-3" style={{color:'#ffffff',backgroundColor:'#202054'}}>Invest</Link>
    //                   </Row>
    //           </div>
    //         </Col>
            
    //       </Row>
      
    //     </div>
    //   );
    // });


    return (
      <React.Fragment>
        {/* <section className="section" id="clients">
          <Container >
            
            <Row className="mt-5 pt-4" >
              <Col lg="12" >
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" >
                  <div className="carousel-inner " >
                    <Slider {...slidesettings} style={{border:"2px solid #404054"}} className="m-5">
                      {clientslides}
                      
                    </Slider>
                    <Slider {...slidesettings1} style={{border:"2px solid #404054"}} className="m-5">
                      {clientslides1}
                      
                    </Slider>
                    <Slider {...slidesettings1}>{clientslides2}</Slider>
                    <Slider  >
                      {clientslides2}
                      
                    </Slider> 
                    <Slider >
                      {clientslides3}
                      
                    </Slider> 

                  </div>
                </div>
              </Col>
            </Row>
            
          </Container>
        </section> */}
        <section class="light">
	<div class="container py-2">
	
		<article class="postcard light ">
			<a class="postcard__img_link">
				<img class="postcard__img" src={Img22} alt="Bidwheelz Logo" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title ">Bid Wheelz</h1>
				
				<div class="postcard__preview-txt">  Bidwheelz is a Marketplace for buying and
                  selling resale vehicles through Transparent Live Bidding powered by Automated
                  Inspection, and ML through Zero Inventory business model. </div>
                  <ul class="postcard__tagbox">
					<li class="tag__item"  ><i class="fas fa-hashtag mr-2"></i>Automobile</li>
					<li class="tag__item" ><i class="fas fa-hashtag mr-2"></i>E-commerce</li>
					<li class=" investbtn"  >
						<a href="/Deals">Invest</a>
					</li>
				</ul>
			</div>
		</article>

    {/* <article class="postcard light ">
			<a class="postcard__img_link" >
				<img class="postcard__img" src={Img11} alt="Brewed Leaf Logo" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title ">Brewed Leaf</h1>
				
				<div class="postcard__preview-txt">We are at Brewed Ventures aim to deliver the freshest tea from the boutique tea gardens within 1 week from the tea gardens to the end consumer.</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"  ><i class="fas fa-hashtag mr-2"></i>Consumer</li>
					<li class="tag__item" ><i class="fas fa-hashtag mr-2"></i>Beverages</li>
					<li class=" investbtn"  >
						<a href="/Login">Invest</a>
					</li>
				</ul>
			</div>
		</article> */}
    </div>
    </section>
      </React.Fragment>
    );
    
  }
}
