import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import CountUp from 'react-countup';

import { Link } from "react-router-dom";
import HomeUrl from '../assets/images/home-border.png';
import Card from 'react-bootstrap/Card' ; 
import Button from 'react-bootstrap/Button' ; 

//import images
import Img1 from '../assets/images/features/img-1.png';
import img from '../assets/images/users/img.jpg' ; 
import details from '../pages/Startups/Details' ; 

import "../assets/css/style.css";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      counters: [
        {
          id: '1',
          extraclass: 'pt-3',
          start: 0,
          end: 1,
          title: "Signup",
          description: 'Create your profile providing specific information about yourself and what you are looking for'
        },
        {
          id: '2',
          extraclass: 'pt-3',
          start: 0,
          end: 2,
          title: "Matching",
          description: 'Allow our AI Algorithm find your ideal match based on your interests'
        },
        {
          id: '3',
          extraclass: 'pt-3',
          start: 0,
          end: 3,
          title: "Investing",
          description: 'Two viewpoints, one platform! Either invest or raise funds'
        },
      ],
      services: [
        {
          id: 'service1',
          icon: 'mdi-cash-multiple',
          title: "Fundraising",
          description: `Your startup has certain ongoing expenses. We help you raise funds to achieve all your financial desires.`
        },
        {
          id: 'service3',
          icon: 'mdi-finance',
          title: "Invest in your favorite startups",
          description: 'Do you believe in the success of a startup? Grab the opportunity and invest in startups you trust will succeed. '
        },
        {
          id: 'service1',
          icon: 'mdi-cursor-pointer',
          title: "Startup solution platform.",
          description: "We provide you with services right from mentorship to legal for running your startup."
        },
        {
          id: 'service2',
          icon: 'mdi-wan',
          title: "Build your network",
          description: 'Interact with others who share your interests while learning about startups and companies in general.'
        },
        {
          id: 'service3',
          icon: 'mdi-account-switch',
          title: "Find the ideal Co-Founder",
          description: 'Finding an ideal Co-founder with the capability of converting your idea into a solution and helping with execution.'
        },
        
      {
          id: 'service2',
          icon: 'mdi-account-group',
          title: "Customer support",
          description: 'If you ever see yourself stuck in the midst of finding your path to success, we are always at your service.'
        }
       
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section " id="services">
          <Container>
          <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">
                    {/* WHICH STARTUP SHOULD YOU INVEST IN? <br/> */}
                    SCROLL TO KNOW MORE</h3>
                  {/* <p className="text-muted f-17 mt-3">
                    WANT TO INVEST IN YOUR FAVORITE STARTUP? KEEP SCROLLING !
                    </p> */}

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-4" id="counter">
              <Col lg={6}>
                <div className="pr-4 mt-4" >
                  <p className="text-uppercase">About BizDateUp</p>
                  <h3>Two Approaches, One point!</h3>
                  <p className="text-muted mt-3">Bizdateup is an online dating platform that enables founders to find and match with their ideal co-founders. Bizdateup allows entrepreneurs to raise capital 
                  through public rounds and the community to invest in their favourite startups. Aside from that, services such as mentorship sessions, legal solutions, pitch decks, and website development 
                  are available to help startups thrive.
</p>
                  <div className="mt-4 pt-1">
                    <Link to="/Deals" className="btn btn-outline-primary">Read more</Link>
                  </div>
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div className={ (counteritem.id !== '1') ? 'mt-4 pt-3' : 'mt-4' } key={key}>
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary"> 
                          <CountUp className="counter-value" delay={2} start={counteritem.start} end={counteritem.end} />{" "}
                            <span className="count-plus text-primary"> +</span></h3>
                          <p className="text-uppercase text-muted mb-0 f-14">{counteritem.title} </p>
                        </div>
                        <div className="media-body pl-4">
                          <p className="text-muted mb-0 mt-3">{counteritem.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4">
                    <div className="services-icon bg-soft-primary">
                      <i className={"mdi text-primary " + item.icon}></i>
                    </div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p className="text-muted mt-3">{item.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
