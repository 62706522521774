import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, Input } from "reactstrap";
import FooterLink from "../Footer/Footer_link";

import LogoDark from "../../assets/images/logo-dark.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          id: 1,
          title: "Important",
          child: [
            { title: "Invest", link: "/Invest" },
            { title: "Raise Funds", link: "#" },
            { title: "Match", link: "/Match" },
            { title: "About Us", link: "/About" },
            { title: "Pricing", link: "/Pricing" },
            
          ],
        },
        {
          id: 2,
          title: "Company",
          child: [
            { title: "Contact", link: "/ContactUs" },
            { title: "Privacy Policy", link: "/Privacypolicy" },
            { title: "Risk of Investment", link: "/Risk" },
            { title: "Terms of use", link: "/Terms" },
            { title: "Refund Policy", link: "/Refundpolicy" },
            { title: "Cancellation Policy", link: "/CancellationPolicy" },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer className="section bg-light bg-footer pb-5">
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footer-info mt-4">
                  <img src={LogoDark} alt="" height="40" />
                  <p className="text-muted mt-4 mb-2">
                    One-stop platform for Startups to connect with a Co-founder,
                    Raise funds and Invest in your favorite startups.
                  </p>
                  <div className="team-social mt-4 pt-2">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <a
                          href="https://www.instagram.com/bizdateup/"
                          target="_blank"
                          className="text-reset"
                        >
                          <i className="mdi mdi-instagram"></i>
                        </a>
                      </li>{" "}
                      <li className="list-inline-item">
                        <a
                          href="https://www.facebook.com/bizdateupindia"
                          target="_blank"
                          className="text-reset"
                        >
                          <i className="mdi mdi-facebook"></i>
                        </a>
                      </li>{" "}
                      <li className="list-inline-item">
                        <a
                          href="https://in.linkedin.com/company/bizdateup"
                          target="_blank"
                          className="text-reset"
                        >
                          <i className="mdi mdi-linkedin"></i>
                        </a>
                      </li>{" "}
                      <li className="list-inline-item">
                        <a
                          href="https://twitter.com/date_biz"
                          target="_blank"
                          className="text-reset"
                        >
                          <i className="mdi mdi-twitter"></i>
                        </a>
                      </li>{" "}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="pl-0 md-lg-5">
                  {/* Render Footer Link */}
                  {this.state.links.map((item, key) => (
                    <Col lg={6} key={key}>
                      <div className="mt-4">
                        <h5 className="f-20">{item.title}</h5>
                        <ul className="list-unstyled footer-link mt-3">
                          {item.child.map((linkItem, key) => (
                            <li key={key}>
                              <Link to={linkItem.link}>{linkItem.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={4}>
                <div className="mt-4">
                  <h5 className="f-20">Subscribe</h5>
                  <div className="subscribe mt-4 pt-1">
                    <Form action="#">
                      <Input
                        placeholder="Enter Email"
                        type="text"
                        style={{ height: "auto" }}
                      />
                      <Button color="primary" className="btn btn-primary">
                        <i className="mdi mdi-send"></i>
                      </Button>
                    </Form>
                    <p
                      className="text-muted mt-4 mb-2"
                      style={{ fontSize: "8px" }}
                    >
                      All trademarks and logos or registered trademarks and
                      logos found on this Site or mentioned herein belong to
                      their respective owners and are solely being used for
                      informational purposes. Information provided herein has
                      been gathered from public sources. Bizdateup Technologies
                      Pvt Ltd disclaims any and all responsibility in connection
                      with veracity of this data. Information presented on this
                      website is for educational purposes only and should not be
                      treated as legal, financial , or any other form of advice.
                      Bizdateup Technologies Pvt Ltd is not liable for financial
                      or any other form of loss incurred by the user or any
                      affiliated party on the basis of information provided
                      herein. Bizdateup Technologies Pvt Ltd is neither a stock
                      exchange nor does it intend to get recognized as a stock
                      exchange under the Securities Contracts Regulation Act,
                      1956. Bizdateup Technologies Pvt Ltd is not authorized by
                      the capital markets regulator to solicit investments. The
                      securities traded on these platforms are not traded on any
                      regulated exchange. Bizdateup also provides that it does
                      not facilitate any online or offline buying, selling, or
                      trading of securities. This Site will be updated on a
                      regular basis
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <hr className="my-5" />
            {/* Render Footer Link End */}
            <FooterLink />
          </Container>
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}

export default Footer;
