import React from 'react';
import { useForm } from 'react-hook-form';
// import { Form, Button } from "react-bootstrap";
import { motion } from 'framer-motion';
import swal from 'sweetalert';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {
    Col,
    Container,
    Form,
    Button,
    FormGroup,
    Row,
    Input,
    Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import Feature4 from '../assets/images/features/img-4.png';
import LogoDark from '../assets/images/logo-dark.png';

import Navbar from '../component/Navbar/NavBar';
import { FormControl } from 'react-bootstrap';

const FirstStep = (props) => {
    const [viewPwd, setViewPwd] = useState(false);
    // state for firstName
    const [firstName, setFirstName] = useState({
        value: '',
        message: '',
        validated: false,
        cl: '',
    });
    // State for lastName
    const [lastName, setLastName] = useState({
        value: '',
        message: '',
        validated: false,
        cl: '',
    });

    // State for Email
    const [email, setEmail] = useState({
        values: '',
        message: '',
        validated: false,
        cl: '',
    });

    // State for Password
    const [password, setPassword] = useState({
        value: '',
        message: '',
        validated: '',
        cl: '',
    });

    const { user } = props;
    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            first_name: user.first_name,
            last_name: user.last_name,
            user_email: user.user_email,
            user_password: user.user_password,
        },
    });

    // Validating the FIRST NAME
    const validateFirstName = (e) => {
        const currName = e.target.value;
        const regex = /^[a-zA-Z]+$/;

        if (currName.length === 0) {
            setFirstName({
                value: '',
                message: 'First Name is Required',
                validated: false,
                cl: 'border border-danger',
            });
        } else if (currName.length > 0 && regex.test(currName)) {
            setFirstName({
                value: currName,
                message: '',
                validated: true,
                cl: 'border border-success',
            });
        } else {
            setFirstName({
                value: '',
                message: 'Invalid characters!',
                validated: false,
                cl: 'border border-danger',
            });
        }
    };

    // Validating LAST-NAME
    const validateLastName = (e) => {
        const currName = e.target.value;
        const regex = /^[a-zA-Z]+$/;

        if (currName.length === 0) {
            setLastName({
                value: '',
                message: 'Last Name is Required',
                validated: false,
                cl: 'border border-danger',
            });
        } else if (currName.length > 0 && regex.test(currName)) {
            setLastName({
                value: currName,
                message: '',
                validated: true,
                cl: 'border border-success',
            });
        } else {
            setLastName({
                value: '',
                message: 'Invalid characters!',
                validated: false,
                cl: 'border border-danger',
            });
        }
    };

    // Validating Email
    const validateEmail = (e) => {
        const currEmail = e.target.value;

        // Using Regular Expression for email validation
        const regex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (currEmail.length === 0) {
            setEmail({
                value: '',
                message: 'Email is Required',
                validated: false,
                cl: 'border border-danger',
            });
        } else if (regex.test(currEmail)) {
            setEmail({
                value: currEmail,
                message: '',
                validated: true,
                cl: 'border border-success',
            });
        } else {
            setEmail({
                value: '',
                message: 'Invalid Email!',
                validated: false,
                cl: 'border border-danger',
            });
        }
    };

    const validatePassword = (e) => {
        const currPass = e.target.value;
        const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,15}$/;

        if (currPass.length === 0) {
            setPassword({
                value: '',
                message: 'Password is Required',
                validated: false,
                cl: 'border border-danger',
            });
        } else if (regex.test(currPass)) {
            setPassword({
                value: currPass,
                message: '',
                validated: true,
                cl: 'border border-success',
            });
        } else {
            setPassword({
                value: '',
                message: 'Should contains UpperCase, LowerCase and a Number',
                validated: false,
                cl: 'border border-danger',
            });
        }
    };

    useEffect(() => {
        if (
            firstName.validated &&
            lastName.validated &&
            email.validated &&
            password.validated
        ) {
            document.getElementById('btn').disabled = false;
        } else {
            document.getElementById('btn').disabled = true;
        }
    }, [firstName, lastName, email, password]);

    // const otp = async (data) => {dffd
    //   const response = await axios.post(
    //     "http://localhost:5000/register",
    //     user.data
    //   )
    //   console.log(response.data.message);

    //   swal({
    //     title: "Enter your OTP",
    //     content:'input',
    //     button: "Validate",
    //   }).then(function(otp) {
    //     if(response.data.otp === otp)
    //     {
    //       swal({
    //         title: "Registration Sucessfull",
    //         icon: `success`,
    //         button: "Okay!!",
    //       }).then(function () {
    //         // window.location = "";
    //       });
    //     }
    //     else
    //     {
    //       swal({
    //         title: "OTP does not match",
    //         icon: `error`,
    //         button: "Okay!!",
    //       })
    //     }

    //   })
    // };

    const items = [
        // { id: 1, idnm: 'home', navheading: 'Home' },
        // { id: 3, idnm: 'services', navheading: 'Services' },
        // { id: 4, idnm: 'pricing', navheading: 'Pricing' },
        // { id: 5, idnm: 'team', navheading: 'Team' },
        // { id: 6, idnm: 'clients', navheading: 'Clients' },
        // { id: 7, idnm: 'contact', navheading: 'Contact' },
    ];

    const [navItems, setNavItems] = useState(items);
    const [pos, setPos] = useState(document.documentElement.scrollTop);
    const [imglight, setImgLight] = useState(false);
    const [navClass, setNavClass] = useState('');
    const [fixTop, setFixTop] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', scrollNavigation, true);

        return () => {
            window.removeEventListener('scroll', scrollNavigation, true);
        };
    });

    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 100) {
            setNavClass('nav-sticky');
            setImgLight(true);
        } else {
            setNavClass('');
            setImgLight(false);
        }
    };

    const onSubmit = (data) => {
        props.updateUser(data);
        console.log(props);
        props.history.push('/second');
    };

    return (
        <div>
            <Navbar
                navItems={navItems}
                navClass={navClass}
                imglight={imglight}
                top={fixTop}
            />

            <section className='vh-100'>
                <div className='display-table'>
                    <div className='display-table-cell'>
                        <Container>
                            <Row className='no-gutters align-items-center'>
                                <Col lg={12}>
                                    <div className='login-box'>
                                        <Row className='align-items-center no-gutters'>
                                            <Col lg={6}>
                                                <div className='bg-light'>
                                                    <div className='row justify-content-center'>
                                                        <div className='col-lg-10'>
                                                            <div className='home-img login-img text-center d-none d-lg-inline-block'>
                                                                <div className='animation-2'></div>
                                                                <div className='animation-3'></div>
                                                                <img
                                                                    src={
                                                                        Feature4
                                                                    }
                                                                    className='img-fluid'
                                                                    alt=''
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <Row className='justify-content-center'>
                                                    <Col lg={11}>
                                                        <div className='p-4'>
                                                            {/* <div className="text-center mt-3">
                                  <Link to="/"><img src={LogoDark} alt=""
                                    height="22" /></Link>
                                  <p className=" mt-3">Sign up for a new Account</p>
                                </div> */}
                                                            <div
                                                                className='p-3 custom-form'
                                                                style={{
                                                                    verticalAlign:
                                                                        'top',
                                                                }}
                                                            >
                                                                <Form
                                                                    onSubmit={handleSubmit(
                                                                        onSubmit
                                                                    )}
                                                                >
                                                                    <motion.div
                                                                        // className="col-md-6 offset-md-3"
                                                                        initial={{
                                                                            x: '-100vw',
                                                                        }}
                                                                        animate={{
                                                                            x: 0,
                                                                        }}
                                                                        transition={{
                                                                            stiffness: 150,
                                                                        }}
                                                                        style={{
                                                                            marginLeft:
                                                                                '10px',
                                                                            marginRight:
                                                                                '10px',
                                                                            margin: 'auto',
                                                                        }}
                                                                    >
                                                                        <FormGroup controlId='first_name'>
                                                                            <Label>
                                                                                First
                                                                                Name
                                                                            </Label>
                                                                            <div id='firstname'>
                                                                                <FormControl
                                                                                    type='text'
                                                                                    name='first_name'
                                                                                    placeholder='Enter your First name'
                                                                                    autoComplete='off'
                                                                                    {...register(
                                                                                        'first_name',
                                                                                        {
                                                                                            required:
                                                                                                'First name is required.',
                                                                                            message:
                                                                                                'First name should contain only characters.',
                                                                                        }
                                                                                    )}
                                                                                    onChange={
                                                                                        validateFirstName
                                                                                    }
                                                                                    className={`${errors} ? "input-error" : "" ${firstName.cl}`}
                                                                                />
                                                                                <div
                                                                                    className={`validateMessage ${
                                                                                        !firstName.validated
                                                                                            ? 'text-danger'
                                                                                            : ''
                                                                                    }`}
                                                                                >
                                                                                    {
                                                                                        firstName.message
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            {errors && (
                                                                                <p className='errorMsg'>
                                                                                    {
                                                                                        errors
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                            {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className="form-validation">Name may contain only alphabets</Form.Label></li></ul>
           */}
                                                                        </FormGroup>

                                                                        <FormGroup controlId='last_name'>
                                                                            <Label>
                                                                                Last
                                                                                Name
                                                                            </Label>
                                                                            <FormControl
                                                                                type='text'
                                                                                name='last_name'
                                                                                placeholder='Enter your Last name'
                                                                                autoComplete='off'
                                                                                {...register(
                                                                                    'last_name',
                                                                                    {
                                                                                        required:
                                                                                            'First name is required.',
                                                                                        message:
                                                                                            'First name should contain only characters.',
                                                                                    }
                                                                                )}
                                                                                onChange={
                                                                                    validateLastName
                                                                                }
                                                                                className={`${errors} ? "input-error" : "" ${lastName.cl}`}
                                                                            />
                                                                            <div
                                                                                className={`validateMessage ${
                                                                                    !lastName.validated
                                                                                        ? 'text-danger'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    lastName.message
                                                                                }
                                                                            </div>
                                                                            {errors && (
                                                                                <p className='errorMsg'>
                                                                                    {
                                                                                        errors
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                            {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className="form-validation">Name may contain only alphabets</Form.Label></li></ul>
           */}
                                                                        </FormGroup>
                                                                        <FormGroup controlId='user_email'>
                                                                            <Label>
                                                                                Email
                                                                            </Label>
                                                                            <FormControl
                                                                                type='email'
                                                                                name='user_email'
                                                                                placeholder='Enter your Email address'
                                                                                autoComplete='off'
                                                                                {...register(
                                                                                    'user_email',
                                                                                    {
                                                                                        required:
                                                                                            'First name is required.',
                                                                                        message:
                                                                                            'First name should contain only characters.',
                                                                                    }
                                                                                )}
                                                                                onChange={
                                                                                    validateEmail
                                                                                }
                                                                                className={`${errors} ? "input-error" : "" ${email.cl} `}
                                                                            />
                                                                            <div
                                                                                className={`validateMessage ${
                                                                                    !email.validated
                                                                                        ? 'text-danger'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    email.message
                                                                                }
                                                                            </div>

                                                                            {errors && (
                                                                                <p className='errorMsg'>
                                                                                    {
                                                                                        errors
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                            {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className="form-validation">Enter a valid email</Form.Label></li></ul> */}
                                                                        </FormGroup>

                                                                        <FormGroup controlId='user_password'>
                                                                            <Label>
                                                                                Password
                                                                            </Label>
                                                                            <FormControl
                                                                                type={
                                                                                    viewPwd
                                                                                        ? 'text'
                                                                                        : 'password'
                                                                                }
                                                                                name='user_password'
                                                                                placeholder='Choose a Password'
                                                                                autoComplete='off'
                                                                                {...register(
                                                                                    'user_password',
                                                                                    {
                                                                                        required:
                                                                                            'First name is required.',
                                                                                        message:
                                                                                            'First name should contain only characters.',
                                                                                    }
                                                                                )}
                                                                                onChange={
                                                                                    validatePassword
                                                                                }
                                                                                className={`${errors} ? "input-error" : "" ${password.cl}`}
                                                                            />
                                                                            <div className='container '>
                                                                                <div className='row justify-content-end c'>
                                                                                    <div className='col-2 d'>
                                                                                        <div
                                                                                            className='eye'
                                                                                            // style={{
                                                                                            //     position:
                                                                                            //         'absolute',
                                                                                            //     right: '2.4rem',
                                                                                            //     top: '20.4rem',

                                                                                            //     fontSize:
                                                                                            //         '15px',
                                                                                            //     cursor: 'pointer',
                                                                                            // }}
                                                                                        >
                                                                                            <i
                                                                                                onClick={() => {
                                                                                                    setViewPwd(
                                                                                                        !viewPwd
                                                                                                    );
                                                                                                }}
                                                                                                className={`fa ${
                                                                                                    viewPwd
                                                                                                        ? 'fa-eye-slash'
                                                                                                        : 'fa-eye'
                                                                                                }`}
                                                                                            ></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className={`validateMessage ${
                                                                                    !password.validated
                                                                                        ? 'text-danger'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    password.message
                                                                                }
                                                                            </div>

                                                                            {errors && (
                                                                                <p className='errorMsg'>
                                                                                    {
                                                                                        errors
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                            {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className="form-validation">Password should be of minimum 8 characters</Form.Label></li></ul>
           */}
                                                                        </FormGroup>

                                                                        <Button
                                                                            id='btn'
                                                                            variant='primary'
                                                                            type='submit'
                                                                        >
                                                                            Next
                                                                        </Button>
                                                                    </motion.div>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default FirstStep;
