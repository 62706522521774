import React, { Component } from "react";

import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

import HomeUrl from "../assets/images/home-border.png";
import Feature from "../assets/images/features/img-3.png";
import NavBar from "./Navbar/NavBar";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
} from "reactstrap";
import Section from "../pages/Layout2/Section";

import { Link } from "react-scroll";
import "../assets/css/style.css";

//sidebar data
const privacySidebar = [
  {
    id: "1",
    title: "Privacy Policy",
    path: "1",
  },
  {
    id: "2",
    title: "Services",
    path: "2",
  },
  {
    id: "3",
    title: "Data we Collect",
    path: "3",
  },
  {
    id: "4",
    title: "Profile",
    path: "4",
  },
  {
    id: "5",
    title: "Legal Disclosures",
    path: "5",
  },
  {
    id: "6",
    title: "Change in Control or Sale",
    path: "6",
  },
  {
    id: "7",
    title: "Security",
    path: "7",
  },
  {
    id: "8",
    title: "Lawful Bases for Processing",
    path: "8",
  },
  {
    id: "9",
    title: "Grievance Redressal Mechanism",
    path: "9",
  },
];

//for smooth scrolling

const Privacy = () => {
  return (
    <React.Fragment>
      <main>
        <section>
          <div className="sidebar">
            <ul>
              {privacySidebar.map((item, id) => {
                return (
                  <>
                    <li className="listitem" key={id}>
                      <Link
                        activeClass="active"
                        to={item.path}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        className="linkstyle3"
                      >
                        {item.title}
                      </Link>
                    </li>

                    {/* <hr className="sidebarHr"/> */}
                  </>
                );
              })}
            </ul>
          </div>
        </section>
      </main>

      <section className="section" id="contact">
        <Container style={{ marginLeft: "20%" }}>
          <section id="1">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-5">Privacy Policy</h3>
                  <p className="text-muteds f-17 mt-3">
                    The privacy policy ("Privacy Policy") governs the use of the
                    BizDateUp platform's website [https://www.bizdateup.com/]
                    ("Website", "we", "us") and the services ("Services") made
                    available through the Website. It has been officially
                    formulated in accordance with the Information Technology Act
                    of 2000 ("Act"), and the Rules enacted are designed to
                    guarantee that the information submitted by its users
                    ("you") is protected to the greatest extent possible.By
                    using the Website, you agree to the terms and circumstances
                    set out in this Privacy Policy regarding the collection,
                    retention, and use of information. You may quit and stop
                    using the Website if you do not agree to the Privacy Policy.
                    The terms and conditions of the Bizdateup platform are
                    incorporated in the Privacy Policy. If the terms of such
                    agreements and the Privacy Policy conflict, the provisions
                    of such agreements shall prevail and govern so long as they
                    pertain to topics expressly specified herein, and this
                    Privacy Policy will apply to all other subjects. Our members
                    share their professional identities, communicate with their
                    network, exchange information and professional insights,
                    post and watch relevant content, learn, and find business
                    and career possibilities. Non-members ("Visitors") can view
                    the content and data on some of our Services.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="2">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Services</h3>
                  <p className="text-muteds f-17 mt-3">
                    This Privacy Statement governs your use of our Services,
                    including our cookie policy. It applies to the sites, apps,
                    communications, and services provided by Business BizDateUp
                    Technologies Pvt Ltd ("Services"). Our privacy policy,
                    including our cookie policy and other documents referred to
                    in the privacy policy, governs the collection, use, and
                    sharing of visitor information. This Privacy Policy can be
                    modified by Bizdateup, On occasion of any changes, the
                    notice for the same will be provided to you for review
                    before putting them into effect.If you object to any
                    changes, you may close your account.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="3">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Data We Collect</h3>
                  <p className="text-muteds f-17 mt-3">
                    To establish an account with us, you must provide complete
                    information. We also collect, store, and process additional
                    information about you, such as your Authentication Token,
                    communications you send, the information you publish in the
                    chat rooms, and any changes you make to the information you
                    submit ("Other Information"). Information such as your IP
                    address, the date and time you visited the Website, and your
                    online activities are automatically collected by us.
                    Third-party services are utilised to monitor and collect
                    data to authenticate your credentials, maintain appropriate
                    security measures, include better Services, fulfil your
                    requests, and improve your user experience. Cookies are also
                    collected by us (a piece of software code that the Website
                    automatically sends to your browser when you access the
                    Website) so as to provide you a better user experience.
                    Information is not linked but stored on the cookies to any
                    personally identifiable information submitted by you.
                    Furthermore, some of our business partners, i.e. third
                    parties working in conjunction with the Website, have
                    cookies on our Website.
                    <br />
                    They employ cookies in accordance with their privacy
                    policies and other website terms and conditions. i.
                    ("Automatically Generated Data") ii. "User Information"
                    includes Personal Information, Other Information, and
                    Automatically Generated Information. We may use and disclose
                    the User Information you provide to third-party websites,
                    affiliates, consultants, and employees in order to manage,
                    collect, store, and process the User Information in order to
                    improve the quality, design, functionality, and content of
                    the Website, as well as to any governmental authority as
                    required by applicable law. The User Information provided by
                    you shall be used to communicate with us through
                    newsletters, updates, notifications, and other forms of
                    communication. In order to verify information provided by
                    you, we may telephonically contact you. The communication
                    with you might be recorded but will be kept confidential
                    otherwise when asked to disclose to any governmental
                    authority under applicable law. The User Information shall
                    be used for purposes only in relation to the Website and not
                    for any other purposes. The User Information shall be
                    retained till the termination of your membership/listing on
                    the Bizdateup platform or up to such other period as may be
                    considered necessary by us for the purpose of operating the
                    Website. You shall have the right to access the information
                    submitted by you, as well as to correct the information and
                    notify us of any changes in the event that the information
                    provided is insufficient or inaccurate. You represent and
                    warrant that the Personal Information and Other Information
                    you supply are true and accurate to the best of your
                    knowledge. You accept and understand that we will not be
                    held liable for the accuracy of the Personal Information and
                    Other Information you supply.You are not required by law to
                    supply us with all of the information. However, if you fail
                    to provide us with certain vital information for the
                    purposes of the Website's Services, the Website's full
                    functionality will be rendered impossible. Without prejudice
                    to the foregoing, you shall have the option, while using the
                    Services otherwise, to withdraw your consent to allow us to
                    use the Personal Information submitted by you and notify us
                    in writing of the same. There are connections on the Website
                    to third-party websites. We are not responsible for any
                    content on such third-party websites, and we will not be
                    held liable if such websites violate our privacy
                    policies.You agree to read and comprehend the privacy
                    policies of any third-party websites. To be clear, our
                    Privacy Policy only applies to User Information collected,
                    received, held, stored, dealt with, or managed for the
                    purposes of providing Services on our Website.
                  </p>

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="4">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Profile</h3>
                  <p className="text-muteds f-17 mt-3">
                    You may customise the information on your profile, including
                    your education, employment experience, skills, photo, city
                    or location, and endorsements. You are not required to enter
                    extra information on your profile; nevertheless, profile
                    information allows you to receive more from our Services,
                    such as assisting recruiters and business prospects in
                    finding you. It is entirely up to you whether or not to add
                    sensitive information.Please do not upload or add personal
                    information to your profile that you do not want others to
                    see. You provide us with more information, for example by
                    synchronising your address book or calendar. As a general
                    rule, we do not gather any information from you when you
                    visit the Website. The information provided by you through
                    email is collected, retained and processed on the Website
                    but not limited to Information such as your name, address,
                    contact details, email id, details of the organisation on
                    behalf of whom you are acting (if applicable) and financial
                    information such as bank account or credit card or debit
                    card or other payment instruments, identity proof,
                    etc(“Personal Information”). You and others may publish
                    material on our Services that includes information about you
                    (as part of articles, posts, comments, or videos). We gather
                    public information about you, such as professional-related
                    news and accomplishments (e.g., patents awarded,
                    professional recognition, conference speakers, projects,
                    etc.), and make it available as part of our Services unless
                    you opt-out. Others may use our Services to sync their
                    contacts or calendar. The information collected by us is
                    through cookies and similar technologies. As further
                    detailed in our Cookie Policy, we use cookies and related
                    technologies (e.g., web beacons, pixels, ad tags, and device
                    identifiers) to recognise you and/or your device(s) on, off,
                    and across multiple Services and devices. Others are also
                    permitted to utilise cookies, as indicated in our Cookie
                    Policy. Cookies can be managed using your browser's settings
                    and other means. You may also opt out of our use of
                    third-party advertising cookies and similar technologies
                    that monitor your behaviour on other websites. We collect
                    information when you visit websites that contain our
                    plugins, advertising, or cookies, or when you check in to
                    third-party services using your Bizdateup account. When you
                    log in to Bizdateup or visit other services that utilise our
                    plugins (such as "Share"), advertisements, cookies, or
                    similar technologies, we obtain information about your
                    visits and interactions with those sites. Only our
                    affiliates, third parties, employees, consultants, screening
                    committee members, mentors, or other officers who are linked
                    with or in relation to the Website's Services will have
                    access to any Personal Information you supply. Such third
                    parties must not share the User Information given to them in
                    any way other than as permitted by us under the terms and
                    conditions of the Privacy policy. Other than as specified
                    above, we will not disclose User Information to any third
                    party without your written consent, except to governmental
                    agencies or bodies as required by applicable law, or to
                    exercise legal rights, defend legal claims, or protect the
                    safety of other users, the public, or our rights. All
                    Members and clients of our Services can see your profile. It
                    may also be visible to others on or off our Services,
                    depending on your preferences (e.g., Visitors to our
                    Services or users of third- party search engines). Your
                    settings, degree of connection with the viewing Member,
                    subscriptions they may have, their usage of our services,
                    access channels, and search types (e.g., by name or by
                    keyword), as detailed in our help centre, all have an impact
                    on the availability of your profile and whether they can
                    view certain fields in your profile.Once individuals login
                    to the Website as a member of the Bizdateup platform, an
                    email notification is sent to them.Furthermore, we may send
                    you periodical newsletters, updates, and other promotional
                    materials notifying you of changes to the Bizdateup
                    platform. We provide you with the opportunity to opt-out of
                    any Services on the Website as a favour to your privacy. If
                    you do not opt out of any Service, you consent to receive
                    information about such Services. To be clear, we only give
                    you information if you have requested it. If you choose to
                    withdraw any Services, you may unsubscribe by using the
                    unsubscribe button in emails sent to you or tabs available
                    on the Website. However, you will not be allowed to opt out
                    of any Service notice that is required in the operation of
                    the Website or that is vital to you as a member of the
                    Bizdateup platform.Legal Foundations for Processing Other
                    services may check up your profile depending on your
                    preferences. Personal data will be made available to other
                    services if you want to link your account with them. When
                    you choose to link the accounts, the sharing and use of your
                    personal data will be outlined in or linked to a consent
                    screen. You can, for example, connect your Twitter or WeChat
                    accounts to share content from our Services, or your email
                    provider may allow you to upload your Bizdateup contacts
                    into its own service. With such accounts, you can withdraw
                    the connection. Others may assist us in providing our
                    Services. Others assist us in providing our services (e.g.,
                    maintenance, analysis, audit, payments, fraud detection,
                    marketing and development). They will have access to your
                    information just as much as is necessary to carry out these
                    duties on our behalf, and they will be bound not to disclose
                    or use it for any other purpose.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="5">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Legal Disclosures</h3>
                  <p className="text-muteds f-17 mt-3">
                    We may need to share your data if we believe it is needed by
                    law or to protect your, our, or others' rights and safety.
                    We may be required to disclose information about you if
                    required by law, subpoena, or other legal process, or if we
                    have a good faith belief that disclosure is reasonably
                    necessary to (1) investigate, prevent, or take action
                    regarding suspected or actual illegal activities, or to
                    assist government enforcement agencies. (2) enforce our
                    agreements with you, (3) investigate and defend ourselves
                    against any third-party claims or allegations, (4) protect
                    the security or integrity of our Service (for example, by
                    sharing with companies facing similar threats), or (5)
                    exercise or protect Bizdateup, our Members, personnel, or
                    others' rights and safety. We make every effort to notify
                    Members of legal demands for their personal data where we
                    believe it is appropriate, unless prohibited by law or court
                    order, or when the request is an emergency. We may oppose
                    such demands if we consider, in our sole judgement, that
                    they are overbroad, ambiguous, or lack appropriate
                    authority, but we do not commit to do so for every
                    demand.The Privacy Policy may be revised, modified, or
                    enhanced at any time at our sole discretion, and the new
                    Privacy Policy will be published on the Website with no
                    further notice. It is your obligation to stay current on
                    changes to the Privacy Policy by routinely checking the
                    Website for updates. Use of the Website's Services in
                    response to a change in its Privacy Policy shall be regarded
                    as your acceptance of the revised Privacy Policy.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="6">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Change in Control or Sale
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    We may share your information if our company is sold, but it
                    must be used in compliance with this Privacy Policy. We may
                    also share your personal information in the course of a
                    sale, merger, or change in ownership, or in anticipation of
                    any of these occurrences. Unless you agree otherwise, any
                    organisation that purchases us or a portion of our business
                    will have the right to continue to use your data in the
                    manner described in this Privacy Policy.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="7">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Security</h3>
                  <p className="text-muteds f-17 mt-3">
                    We will govern and protect User Information in accordance
                    with the Act's mandated security practices and procedures,
                    as more specifically described in the Information Technology
                    (Reasonable Security Practices & Procedures and Sensitive
                    Personal Data or Information) Rules, 2011 and the
                    Information Technology (Intermediary Guidelines) Rules,
                    2011. As mentioned in Clause 5 of the Privacy Policy, we
                    take reasonable efforts to secure User Information.
                    Employees, consultants, screening committee members,
                    mentors, and officers must all be kept up to date on
                    security and privacy policies and methods.Regardless of the
                    foregoing, despite our efforts to maintain the
                    confidentiality of the User Information, we may be unable to
                    secure or prevent unauthorised access or use, software
                    failure or defect, or other causes that may jeopardise the
                    security of the User Information. You acknowledge and accept
                    that the Internet is not a secure medium and that we cannot
                    guarantee the security of such User Information.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="12">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Lawful Bases for Processing
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    We have legal grounds to collect, use, and distribute
                    information about you. You have options regarding how we
                    utilise your data. You can withdraw your consent at any time
                    by heading to settings. We will only collect and process
                    personal data about you if we have a legal basis to do
                    so.Consent (where you have given consent), contract (where
                    processing is required for the performance of a contract
                    with you (e.g. to offer the Bizdateup Services you have
                    requested), and "legitimate interests" are all legal bases.
                    Where we depend on your consent to process personal data,
                    you have the right to withdraw or decline your consent at
                    any time, and you have the right to object where we rely on
                    legitimate interests. Please contact us at
                    Bizdateup@entremax.in if you have any queries about the
                    legal bases on which we collect and use your personal data.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="9">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    Grievance Redressal Mechanism
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    The Website shall appoint a grievance redressal officer
                    ("Grievance Officer") to address any of your grievances or
                    inaccuracies in the information published on the Website.As
                    specified by the Act, the Grievance Officer must resolve all
                    grievances within one month of receipt. If you have any
                    queries or complaints about our Policy, please contact
                    Bizdateup first. You can also write to us at Bizdateup G2,
                    EMPIRE BUSINESS CENTRE, EMPIRE COMPLEX, 414 Senapati Bapat
                    Marg, Lower Parel, near Shreeniwas Mill Delisle Road, Mumbai
                    -400013.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Privacy;
