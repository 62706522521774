import React, { useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import FirstStep from '../component/FirstStep';
import Header from '../component/Header';
import Login from '../component/1Login';
import SecondStep from '../component/SecondStep';
import ThirdStep from '../component/ThirdStep';
import FourthStep from '../component/FourthStep';
import Deal from '../pages/Deals';

import Layout2 from '../pages/Layout2/Layout2';
import Layout3 from '../pages/Layout3/Layout3';
import Layout4 from '../pages/Layout4/Layout4';
import Layout1 from '../pages/Layout1/Layout1';
import AdminDatabase from '../component/adminDatabase';

const AppRouter = () => {
    const [user, setUser] = useState({});

    const updateUser = (data) => {
        setUser((prevUser) => ({ ...prevUser, ...data }));
    };

    const resetUser = () => {
        setUser({});
    };

    return (
        <BrowserRouter>
            <div className='container'>
                {/* <Header /> */}
                <Switch>
                    <Route
                        render={(props) => (
                            <div>
                                <Header />
                                <FirstStep
                                    {...props}
                                    user={user}
                                    updateUser={updateUser}
                                />
                            </div>
                        )}
                        path='/first'
                        exact={true}
                    />
                    <Route
                        render={(props) => (
                            <div>
                                <Header />
                                <SecondStep
                                    {...props}
                                    user={user}
                                    updateUser={updateUser}
                                />
                            </div>
                        )}
                        path='/second'
                    />
                    <Route
                        render={(props) => (
                            <div>
                                <Header />
                                <FourthStep
                                    {...props}
                                    user={user}
                                    updateUser={updateUser}
                                />
                            </div>
                        )}
                        path='/fourth'
                    />
                    <Route
                        render={(props) => (
                            <div>
                                <Header />
                                <ThirdStep
                                    {...props}
                                    user={user}
                                    updateUser={updateUser}
                                    resetUser={resetUser}
                                />
                            </div>
                        )}
                        path='/third'
                    />
                    <Route component={Login} path='/login' />

                    <Route component={Deal} path='/Deals' />

                    <Route component={Layout2} path='/About' />
                    <Route component={Layout1} exact path='/' />
                    <Route component={Layout3} path='/Match' />
                    <Route component={Layout4} path='/Invest' />
                    <Route component={AdminDatabase} exact path='/admin' />

                    <Route render={() => <Redirect to='/first' />} />
                </Switch>
            </div>
        </BrowserRouter>
    );
};

export default AppRouter;
