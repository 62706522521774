import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// import { Form, Button } from "react-bootstrap";
// import csc from 'country-state-city';
import axios from 'axios';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import { BASE_API_URL } from '../utils/constants';
import { useHistory } from 'react-router-dom';
import '../assets/css/style.css';

import { FormControl } from 'react-bootstrap';
import {
    Col,
    Container,
    Form,
    Button,
    FormGroup,
    Row,
    Input,
    Label,
} from 'reactstrap';

import Feature4 from '../assets/images/features/img-4.png';
import LogoDark from '../assets/images/logo-dark.png';
// import ToggleButton from "react-bootstrap/ToggleButton";
// import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
// import {Multiselselect} from 'multiselect-react-dropdown';
import Select from 'react-select';

import Progress from './Progress';
import { Link } from 'react-router-dom';
import Navbar from '../component/Navbar/NavBar';
import Spinner from './Spinner';

const ThirdStep = (props) => {
    var [value, getValue] = useState();
    var [valueH, getValueH] = useState();
    const [disable, setdisable] = useState(false);
    const [disableH, setdisableH] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [selectedstartup_age, setSelectedstartup_age] = useState('');
    const [check1, setCheck] = useState();
    const [check2, setCheck2] = useState();
    const [check3, setCheck3] = useState();
    const [check4, setCheck4] = useState();
    const [spinner, showSpinner] = useState(false);

    const { user } = props;
    let history = useHistory();
    const { register, errors } = useForm({
        defaultValues: {
            description: user.description,
        },
    });

    const items = [
        // { id: 1, idnm: "home", navheading: "Home" },
        // { id: 3, idnm: "services", navheading: "Services" },
        // { id: 4, idnm: "pricing", navheading: "Pricing" },
        // { id: 5, idnm: "team", navheading: "Team" },
        // { id: 6, idnm: "clients", navheading: "Clients" },
        // { id: 7, idnm: "contact", navheading: "Contact" },
    ];

    const [navItems, setNavItems] = useState(items);
    const [pos, setPos] = useState(document.documentElement.scrollTop);
    const [imglight, setImgLight] = useState(false);
    const [navClass, setNavClass] = useState('');
    const [fixTop, setFixTop] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', scrollNavigation, true);

        return () => {
            window.removeEventListener('scroll', scrollNavigation, true);
        };
    });

    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 100) {
            setNavClass('nav-sticky');
            setImgLight(true);
        } else {
            setNavClass('');
            setImgLight(false);
        }
    };

    // let skills = [
    //   {
    //     id: 1 ,
    //     name: 'Cpp',
    //     select: false
    //   },
    //   {
    //     id: 2 ,
    //     name: 'Management',
    //     select: false
    //   },
    //   {
    //     id: 3,
    //     name: 'Development',
    //     select: false
    //   },
    //   {
    //     id: 4 ,
    //     name: 'Frontend',
    //     select: false
    //   },
    //   {
    //     id: 5 ,
    //     name: 'Backend',
    //     select: false
    //   },

    // ];

    let startup_age = [
        {
            id: 1,
            name: 'Ideation',
        },
        {
            id: 2,
            name: 'launch',
        },
        {
            id: 3,
            name: 'Early',
        },
        {
            id: 4,
            name: 'Growth',
        },
        {
            id: 5,
            name: 'Scale',
        },
    ];

    let skills = [
        {
            value: 1,
            label: 'Cpp',
        },
        {
            value: 2,
            label: 'Management',
        },
        {
            value: 3,
            label: 'Development',
        },
        {
            value: 4,
            label: 'Frontend',
        },
        {
            value: 5,
            label: 'Backend',
        },
    ];

    useEffect(() => {
        console.log(props.user);
        const getstartup_age = async () => {
            try {
                setIsLoading(true);
                // allCountries = result?.map(({ isoCode, name }) => ({
                //   isoCode,
                //   name
                // }));
                const [{ id: first_startup_age } = {}] = startup_age;
                setSelectedstartup_age(first_startup_age);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };

        getstartup_age();
    }, []);

    const handleSubmit = async (event) => {
        showSpinner(true);
        event.preventDefault();
        console.log(true);
        console.log(12);

        try {
            const { user } = props;
            let sk = '';
            // console.log(value.length) ;
            // for (var i=0; i< value.length; i++) {
            //   //console.log(value[i]);
            //   sk += skills[value[i]].name + ' ' ;
            // }
            //console.log(sk) ;
            // const updatedData = {
            //   startup_age: startup_age.find(
            //     (startup_age) => {
            //     return (startup_age.id == selectedstartup_age)
            //     }
            //   )?.name,
            //   skill: value,
            //   skillH: valueH
            // };
            let imageFileName = '';
            try {
                const res = await axios.post(
                    `${BASE_API_URL}/upload`,
                    props.user.imageAddress
                );
                console.log(res.data);
                imageFileName = res.data.file;
            } catch (err) {
                console.log(err);
            }
            props.user.imageAddress = imageFileName;
            console.log(12);

            await axios.post(`${BASE_API_URL}/register`, {
                ...user,
                // ...updatedData
            });

            // await axios.post('http://localhost:3000/register', {
            //     ...user,
            // });

            localStorage.setItem('loggedIn', JSON.stringify(user));
            console.log(user);
            showSpinner(false);

            Swal.fire({
                icon: 'success',
                // confirmButtonText:'Invest Now',
                // buttonsStyling: false,
                // customClass: {
                //   confirmButton: 'invest-sweet-alert-button',

                // },
                showConfirmButton: false,

                html: `
  <div>
  <p class="titlemodal"> Thank you! you've been successfully signed up.  </p>
  
  <a href="/Deals">
  <button class="invest-sweet-alert-button">Invest Now </button>
  </a>
  
  <div class="team-social mt-4 pt-2 iconmodal">
  <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a
                          href="https://www.instagram.com/bizdateup/"
                          target="_blank"
                          class="text-reset"
                        >
                        <i class="mdi mdi-instagram"></i>
                          
                        </a>
                        
                        </li>
                        <li class="list-inline-item">
                        <a
                        href="https://www.facebook.com/bizdateupindia"
                        target="_blank"
                        class="text-reset"
                        >
                        <i class="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                        href="https://in.linkedin.com/company/bizdateup"
                        target="_blank"
                        class="text-reset"
                        >
                        <i class="mdi mdi-whatsapp"></i>
                        </a>
                        </li>
                        
                        </ul>
                        </div>
                        </div>
                        `,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.resetUser();
                    // props.history.push('/Deals')
                    console.log('Signed up');
                    // window.location.href = 'Deals'
                }
            });
            // history.push('/')
        } catch (error) {
            console.log(error);
            if (error.response) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data,
                });
                console.log('error', error.response.data);
            }
            history.push('/Deals');
        }
    };
    var handleChange1 = (val) => {
        setCheck(val.target.checked);

        console.log(check1);
    };

    var handleChange2 = (val) => {
        setCheck2(val.target.checked);

        console.log(check2);
    };

    var handleChange3 = (val) => {
        setCheck3(val.target.checked);

        console.log(check3);
    };

    var handleChange4 = (val) => {
        setCheck4(val.target.checked);

        console.log(check4);
    };

    const handleChangeH = (val) => {
        getValueH(Array.isArray(val) ? val.map((x) => x.label) : []);
        console.log(val);
    };

    const [options] = useState(skills);

    return (
        <div>
            <Navbar
                navItems={navItems}
                navClass={navClass}
                imglight={imglight}
                top={fixTop}
            />

            {spinner ? (
                <Spinner />
            ) : (
                <>
                    <section className='vh-100'>
                        <div className='display-table'>
                            <div className='display-table-cell'>
                                <Container>
                                    <Row className='no-gutters align-items-center'>
                                        <Col lg={12}>
                                            <div className='login-box'>
                                                <Row className='align-items-center no-gutters'>
                                                    <Col lg={6}>
                                                        <div className='bg-light'>
                                                            <div className='row justify-content-center'>
                                                                <div className='col-lg-10'>
                                                                    <div className='home-img login-img text-center d-none d-lg-inline-block'>
                                                                        <div className='animation-2'></div>
                                                                        <div className='animation-3'></div>
                                                                        <img
                                                                            src={
                                                                                Feature4
                                                                            }
                                                                            className='img-fluid'
                                                                            alt=''
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Row className='justify-content-center'>
                                                            <Col lg={11}>
                                                                <div className='p-4'>
                                                                    <div className='p-3 custom-form'>
                                                                        <Form
                                                                            onSubmit={
                                                                                handleSubmit
                                                                            }
                                                                        >
                                                                            <motion.div
                                                                                className='col-md-12 offset-md-1 multi-step-form'
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '0px',
                                                                                    marginRight:
                                                                                        '0px',
                                                                                }}
                                                                                initial={{
                                                                                    x: '-100vw',
                                                                                }}
                                                                                animate={{
                                                                                    x: 0,
                                                                                }}
                                                                                transition={{
                                                                                    stiffness: 150,
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        padding:
                                                                                            '0.7rem 1.4rem',
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <h5 class='mt-4'>
                                                                                            1.
                                                                                            Privacy
                                                                                            Policy
                                                                                        </h5>
                                                                                        {/* <p>
              Vivamus varius urna id nisi consequat scelerisque. Nullam massa
              mauris, pretium ut erat sit amet, fermentum fermentum augue.
            </p> */}
                                                                                        <FormGroup controlId='check1'>
                                                                                            {isLoading && (
                                                                                                <p className='loading'>
                                                                                                    Loading.
                                                                                                    Please
                                                                                                    wait...
                                                                                                </p>
                                                                                            )}
                                                                                            {/* <Form.Label>I Agree to Privacy policy</Form.Label><br/> */}
                                                                                            <div className='tncContainer'>
                                                                                                <input
                                                                                                    style={{
                                                                                                        marginRight:
                                                                                                            '1rem',
                                                                                                    }}
                                                                                                    type='checkbox'
                                                                                                    name='check'
                                                                                                    // placeholder="Password"
                                                                                                    // value={formValues.password}
                                                                                                    onChange={
                                                                                                        handleChange1
                                                                                                    }
                                                                                                />{' '}
                                                                                                <span id='asd'>
                                                                                                    <strong>
                                                                                                        I
                                                                                                        Agree
                                                                                                        to{' '}
                                                                                                        <a
                                                                                                            href='https://www.bizdateup.com/Privacypolicy'
                                                                                                            style={{
                                                                                                                textDecoration:
                                                                                                                    'none',
                                                                                                                color: '#FF0000',
                                                                                                            }}
                                                                                                            target='_blank'
                                                                                                        >
                                                                                                            <strong>
                                                                                                                Privacy
                                                                                                                Policy
                                                                                                            </strong>{' '}
                                                                                                        </a>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </div>

                                                                                    <div>
                                                                                        <h5 class='mt-4'>
                                                                                            2.
                                                                                            Terms
                                                                                            of
                                                                                            Use
                                                                                        </h5>
                                                                                        {/* <p>
              Suspendisse non venenatis velit. Proin commodo lacinia bibendum.
              Phasellus suscipit lacus in diam accumsan tincidunt.
            </p> */}
                                                                                        <FormGroup controlId='check2'>
                                                                                            {isLoading && (
                                                                                                <p className='loading'>
                                                                                                    Loading.
                                                                                                    Please
                                                                                                    wait...
                                                                                                </p>
                                                                                            )}
                                                                                            {/* <Form.Label>I Agree to Terms of use</Form.Label><br/> */}
                                                                                            <div className='tncContainer'>
                                                                                                <input
                                                                                                    style={{
                                                                                                        marginRight:
                                                                                                            '1rem',
                                                                                                    }}
                                                                                                    type='checkbox'
                                                                                                    name='check'
                                                                                                    // placeholder="Password"
                                                                                                    // value={formValues.password}
                                                                                                    onChange={
                                                                                                        handleChange2
                                                                                                    }
                                                                                                />{' '}
                                                                                                <span id='asd'>
                                                                                                    <strong>
                                                                                                        I
                                                                                                        Agree
                                                                                                        to{' '}
                                                                                                        <a
                                                                                                            href='https://www.bizdateup.com/Terms'
                                                                                                            style={{
                                                                                                                textDecoration:
                                                                                                                    'none',
                                                                                                                color: '#FF0000',
                                                                                                            }}
                                                                                                            target='_blank'
                                                                                                        >
                                                                                                            <strong>
                                                                                                                Terms
                                                                                                                Of
                                                                                                                Use
                                                                                                            </strong>{' '}
                                                                                                        </a>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </div>

                                                                                    <div>
                                                                                        <h5 class='mt-4'>
                                                                                            3.
                                                                                            Risk
                                                                                            of
                                                                                            Investment
                                                                                        </h5>
                                                                                        {/* <p>
              Aenean vulputate lacinia velit, pulvinar ultricies magna. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p> */}
                                                                                        <FormGroup controlId='check3'>
                                                                                            {isLoading && (
                                                                                                <p className='loading'>
                                                                                                    Loading.
                                                                                                    Please
                                                                                                    wait...
                                                                                                </p>
                                                                                            )}
                                                                                            {/* <Form.Label>I Agree to Risk in investment</Form.Label><br/> */}
                                                                                            <div className='tncContainer'>
                                                                                                <input
                                                                                                    style={{
                                                                                                        marginRight:
                                                                                                            '1rem',
                                                                                                    }}
                                                                                                    type='checkbox'
                                                                                                    name='check'
                                                                                                    // placeholder="Password"
                                                                                                    // value={formValues.password}
                                                                                                    onChange={
                                                                                                        handleChange3
                                                                                                    }
                                                                                                />{' '}
                                                                                                <span id='asd'>
                                                                                                    <strong>
                                                                                                        I
                                                                                                        Agree
                                                                                                        to{' '}
                                                                                                        <a
                                                                                                            href='https://www.bizdateup.com/Risk'
                                                                                                            style={{
                                                                                                                textDecoration:
                                                                                                                    'none',
                                                                                                                color: '#FF0000',
                                                                                                            }}
                                                                                                            target='_blank'
                                                                                                        >
                                                                                                            <strong>
                                                                                                                Risk
                                                                                                                in
                                                                                                                Investment
                                                                                                            </strong>{' '}
                                                                                                        </a>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </div>

                                                                                    <div>
                                                                                        <h5 class='mt-4'>
                                                                                            4.
                                                                                            Refund
                                                                                            Policy
                                                                                        </h5>
                                                                                        {/* <p>
              Morbi sodales justo metus, ut feugiat ipsum condimentum a. Sed
              fermentum ante mattis erat pulvinar volutpat ac molestie eget
              vehicula.
            </p> */}
                                                                                        <FormGroup controlId='check4'>
                                                                                            {isLoading && (
                                                                                                <p className='loading'>
                                                                                                    Loading.
                                                                                                    Please
                                                                                                    wait...
                                                                                                </p>
                                                                                            )}
                                                                                            {/* <Form.Label>I Agree to Declaration</Form.Label><br/> */}
                                                                                            <div className='tncContainer'>
                                                                                                <input
                                                                                                    style={{
                                                                                                        marginRight:
                                                                                                            '1rem',
                                                                                                    }}
                                                                                                    type='checkbox'
                                                                                                    name='check'
                                                                                                    // placeholder="Password"
                                                                                                    // value={formValues.password}
                                                                                                    onChange={
                                                                                                        handleChange4
                                                                                                    }
                                                                                                />{' '}
                                                                                                <span id='asd'>
                                                                                                    <strong>
                                                                                                        I
                                                                                                        Agree
                                                                                                        to{' '}
                                                                                                        <a
                                                                                                            href='https://www.bizdateup.com/Refundpolicy'
                                                                                                            style={{
                                                                                                                textDecoration:
                                                                                                                    'none',
                                                                                                                color: '#FF0000',
                                                                                                            }}
                                                                                                            target='_blank'
                                                                                                        >
                                                                                                            <strong>
                                                                                                                Refund
                                                                                                                Policy
                                                                                                            </strong>{' '}
                                                                                                        </a>
                                                                                                    </strong>
                                                                                                </span>
                                                                                            </div>
                                                                                        </FormGroup>
                                                                                    </div>

                                                                                    <Button
                                                                                        style={{
                                                                                            marginTop:
                                                                                                '1.5rem',
                                                                                        }}
                                                                                        variant='primary'
                                                                                        type='submit'
                                                                                        disabled={
                                                                                            !(
                                                                                                check1 &&
                                                                                                check2 &&
                                                                                                check3 &&
                                                                                                check4
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Register
                                                                                    </Button>
                                                                                </div>
                                                                            </motion.div>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

export default ThirdStep;
