import React, { Component } from "react";

import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

import HomeUrl from "../assets/images/home-border.png";
import Feature from "../assets/images/features/img-3.png";
import NavBar from "./Navbar/NavBar";
import {
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
} from "reactstrap";
import Section from "../pages/Layout2/Section";

import { Link } from "react-scroll";
import "../assets/css/style.css";

//sidebar data
const privacySidebar = [
  {
    id: "1",
    title: "Cancellation Policy",
    path: "1",
  },
  {
    id: "2",
    title: "All sales are not final",
    path: "2",
  },
  {
    id: "3",
    title: "Refund cost",
    path: "3",
  },
  {
    id: "4",
    title: "Time Frame to return",
    path: "4",
  },
  {
    id: "5",
    title: "Digital products",
    path: "5",
  },
  {
    id: "6",
    title: "Form of refund",
    path: "6",
  },
];

//for smooth scrolling

const Privacy = () => {
  return (
    <React.Fragment>
      <main>
        <section>
          <div className="sidebar">
            <ul>
              {privacySidebar.map((item, id) => {
                return (
                  <>
                    <li className="listitem" key={id}>
                      <Link
                        activeClass="active"
                        to={item.path}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        className="linkstyle3"
                      >
                        {item.title}
                      </Link>
                    </li>

                    {/* <hr className="sidebarHr"/> */}
                  </>
                );
              })}
            </ul>
          </div>
        </section>
      </main>

      <section className="section" id="contact">
        <Container style={{ marginLeft: "20%" }}>
          <section id="1">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-5">Cancellation Policy</h3>
                  <p className="text-muteds f-17 mt-3">
                    A cancellation is a notice sent by a company to a client,
                    informing them that an erroneous trade has been made and is
                    being rectified. When processing cancellations, industry
                    best practices require that company maintain detailed
                    records on all the actions taken to correct the mistaken
                    trades.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="2">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">
                    All sales are not final
                  </h3>
                  <p className="text-muteds f-17 mt-3">
                    All sales are not final implies that a customer can cancel
                    and exchange or claim a refund on the purchase. Most
                    successful websites employ this clause in their policy to
                    enable customers the added choice of return and refund. All
                    sales are final would imply the opposite, that a customer
                    would not be able to cancel or return a good once a purchase
                    has been made.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="3">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Refund cost</h3>
                  <p className="text-muteds f-17 mt-3">
                    The cost for refund is borne by either the customer or the
                    company. This should be stated clearly in the return and
                    cancellation policy on the website. There shouldn’t be any
                    hidden cost for cancellation and refund. The payment
                    procedure for the same should be mentioned in the policy as
                    well.
                  </p>

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="4">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Time Frame to return</h3>
                  <p className="text-muteds f-17 mt-3">
                    The policy should explicitly state the time period allocated
                    for cancellation. This means the time frame a customer has
                    to cancel an order. The policy should also state the time
                    period allotted for refund. This means the number of days it
                    will take for a customer to get his money back after making
                    a return. The time frame for malfunctioning and damaged
                    products generally differ from the normal
                    cancellation/exchange of a good. Sometimes a customer may
                    get credit or an exchange offer instead of a refund. The
                    duration allotted for such an exchange or credit and date of
                    expiry of exchange should be stated as a main sub point in
                    the policy.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="5">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Digital products</h3>
                  <p className="text-muteds f-17 mt-3">
                    Digital products may or may not have refunds on purchases of
                    such products. Sometimes a download link may not work and
                    the company may give a refund in such rare cases
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>

          <section id="6">
            <Row>
              <Col lg={12}>
                <div className="title-box text-left">
                  <h3 className="title-heading mt-4">Form of refund</h3>
                  <p className="text-muteds f-17 mt-3">
                    The website should also state the forms of refund available
                    to a customer. A product can be exchanged for cash in terms
                    of a refund, store credit or exchanged for another product
                    altogether.The criteria for eligibility of refund should
                    also be included in this point. (example: return a good in
                    its original condition, within a time period, etc.) Most
                    successful online companies offer their customers the option
                    to select the form of refund, return or exchange as per the
                    convenience of the customer.
                  </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Privacy;
