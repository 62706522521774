import React, { useState, useEffect, Component } from 'react';
import { set, useForm } from 'react-hook-form';
// import { Form, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import axios from 'axios';
import { BASE_API_URL } from '../utils/constants';

import {
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Button,
  Input,
  Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormControl } from 'react-bootstrap';

import Feature4 from '../assets/images/features/img-4.png';
import LogoDark from '../assets/images/logo-dark.png';

import Navbar from '../component/Navbar/NavBar';

const SecondStep = (props) => {
  //const [designation, setDesignation] = useState([]);
  // const [gender, setGender] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const [selectedDesignation, setSelectedDesignation] = useState('');
  const [selectedGender, setSelectedGender] = useState('');

  const { user } = props;
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      phone: user.phone,
      age: user.age,
      pan: user.pan,
      aadhar: user.aadhar,
    },
  });

  const items = [
    // { id: 1, idnm: "home", navheading: "Home" },
    // { id: 3, idnm: "services", navheading: "Services" },
    // { id: 4, idnm: "pricing", navheading: "Pricing" },
    // { id: 5, idnm: "team", navheading: "Team" },
    // { id: 6, idnm: "clients", navheading: "Clients" },
    // { id: 7, idnm: "contact", navheading: "Contact" },
  ];

  const [navItems, setNavItems] = useState(items);
  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImgLight] = useState(false);
  const [navClass, setNavClass] = useState('');
  const [fixTop, setFixTop] = useState(true);

  const [image, setImage] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);

    return () => {
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  });

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 100) {
      setNavClass('nav-sticky');
      setImgLight(true);
    } else {
      setNavClass('');
      setImgLight(false);
    }
  };

  const [imageValidation, setImageValidation] = useState({
    message: '',
    cl: '',
  });

  const [pan, setPan] = useState({
    value: '',
    message: '',
    validated: false,
    cl: '',
  });
  const [aadhar, setAadhar] = useState({
    value: '',
    message: '',
    validated: false,
    cl: '',
  });

  const [mobile, setMobile] = useState({
    value: '',
    message: '',
    validated: false,
    cl: '',
  });

  const [age, setAge] = useState({
    value: '',
    message: '',
    validated: false,
    cl: '',
  });

  const [genderUI, setGenderUI] = useState('');

  let designation = [
    {
      id: 1,
      name: 'Founder',
    },
    {
      id: 2,
      name: 'Co-founder',
    },
    {
      id: 3,
      name: 'Professional',
    },
    {
      id: 4,
      name: 'Freelancer',
    },
  ];

  let gender = [
    {
      id: 2,
      name: 'Female',
    },
    {
      id: 1,
      name: 'Male',
    },

    {
      id: 3,
      name: 'Other',
    },
  ];

  // useEffect(() => {
  //   const getDesignation = async () => {
  //     try {
  //       setIsLoading(true);
  //       // allCountries = result?.map(({ isoCode, name }) => ({
  //       //   isoCode,
  //       //   name
  //       // }));
  //       const [{ id: first_designation } = {}] = designation;
  //       setSelectedDesignation(first_designation);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       //setDesignation([]);
  //       setIsLoading(false);
  //     }
  //   };

  //   getDesignation();
  // }, []);

  useEffect(() => {
    if (
      aadhar.validated &&
      image &&
      pan.validated &&
      mobile.validated &&
      age.validated &&
      genderUI !== ''
    ) {
      document.getElementById('btn').disabled = false;
    } else {
      document.getElementById('btn').disabled = true;
    }
  }, [aadhar, pan, mobile, age, genderUI]);

  useEffect(() => {
    const getGender = async () => {
      try {
        setIsLoading(true);
        // allCountries = result?.map(({ isoCode, name }) => ({
        //   isoCode,
        //   name
        // }));
        const [{ id: first_gender } = {}] = gender;
        setSelectedGender(first_gender);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    getGender();
  }, []);

  const handleImage = (e) => {
    e.preventDefault();
    setImage(e.target.files[0]);
    const size = e.target.files[0].size;
    if (size > 5120000) {
      document.getElementById('btn').disabled = true;
      setImageValidation({
        message: 'Image size limit exceeded (>5 Mb)',
        cl: 'border border-danger',
      });
    } else {
      setImageValidation({
        message: '',
        cl: 'border border-success',
      });
      document.getElementById('btn').disabled = false;
    }
  };

  const validateAadhar = (e) => {
    const currAadhar = e.target.value;

    // Aadhar consists of 12 random digits and should not start with 0
    if (currAadhar.length === 0) {
      setAadhar({
        value: currAadhar,
        message: 'Aadhar Number is Required',
        validated: false,
        cl: 'border border-danger',
      });
    } else if (
      currAadhar.length === 12 &&
      currAadhar.charAt(0) !== '0'
    ) {
      setAadhar({
        value: currAadhar,
        message: '',
        validated: true,
        cl: 'border border-success',
      });
    } else {
      setAadhar({
        value: '',
        message: 'Invalid Aadhar details!',
        validated: false,
        cl: 'border border-danger',
      });
    }
  };

  const validatePAN = (e) => {
    const currPAN = e.target.value;

    // PAN CONTAINS 10 aphanumericals
    //  - first 5 are letters
    //  - next 4 are numbers
    //  - last one is letter

    if (currPAN.length === 10) {
      const first5Letters = currPAN.slice(0, 5);
      const middleNumbers = currPAN.slice(5, 9);
      const lastLetter = currPAN.charAt(9);

      if (
        /^[A-Z]+$/i.test(first5Letters) &&
        /^[A-Z]+$/i.test(lastLetter) &&
        /^[0-9]+$/.test(middleNumbers)
      ) {
        setPan({
          value: currPAN,
          message: '',
          validated: true,
          cl: 'border border-success',
        });
      } else {
        setPan({
          value: '',
          message: 'Invalid PAN details!',
          validated: false,
          cl: 'border border-danger',
        });
      }
    } else {
      setPan({
        value: '',
        message: 'Invalid PAN details!',
        validated: false,
        cl: 'border border-danger',
      });
    }
  };

  const validateMobile = (e) => {
    const currPhone = e.target.value;

    if (currPhone.length === 0) {
      setMobile({
        value: '',
        message: 'Mobile Number is Required',
        validated: false,
        cl: 'border border-danger',
      });
    } else if (!/^[0-9]+$/.test(currPhone)) {
      setMobile({
        value: '',
        message: 'Invalid Number',
        validated: false,
        cl: 'border border-danger',
      });
    } else if (currPhone.length !== 10) {
      setMobile({
        value: '',
        message: 'Mobile No. should contain 10 digits!',
        validated: false,
        cl: 'border border-danger',
      });
    } else {
      setMobile({
        value: currPhone,
        message: '',
        validated: true,
        cl: 'border border-success',
      });
    }
  };

  const validateAge = (e) => {
    const currAge = e.target.value;

    if (currAge.length === 0) {
      setAge({
        value: '',
        message: 'Age is Required',
        validated: false,
        cl: 'border border-danger',
      });
    } else if (currAge < 18) {
      setAge({
        value: '',
        message: 'Minimum 18 Required',
        validated: false,
        cl: 'border border-danger',
      });
    } else {
      setAge({
        value: currAge,
        message: '',
        validated: true,
        cl: 'border border-success',
      });
    }
  };

  const handleGenderUI = (e) => {
    // console.log("genderUI RUNNED")
    setGenderUI('border border-success');
  };

  const onSubmit = async (data) => {
    // console.log(document.getElementById('image').files[0]);
    // const file = document.getElementById('image').files[0];

    const fd = new FormData();
    if (image) {
      fd.append('file', image, image.name);

      // try {
      //     const res = await axios.post(`${BASE_API_URL}/upload`, fd);
      //     console.log(res.data);
      //     imageFileName = res.data;
      // } catch (err) {
      //     console.log(err);
      // }
    }

    const updatedData = {
      // designations: designation.find(
      //   (designation) => {
      //     return (designation.id == selectedDesignation)
      //   }
      // )?.name,
      genders: gender.find((gender) => {
        return gender.id == selectedGender;
      })?.name,

      imageAddress: fd,
    };
    // console.log(designation);
    // console.log(gender);
    // console.log(updatedData.designations);
    // console.log(updatedData.genders);

    props.updateUser({
      ...data,
      ...updatedData,
    });
    // console.log(props.user);
    props.history.push('/third');
  };

  return (
    <div>
      <Navbar
        navItems={navItems}
        navClass={navClass}
        imglight={imglight}
        top={fixTop}
      />

      <section className=" vh-100">
        <div className="display-table">
          <div className="display-table-cell">
            <Container>
              <Row className="no-gutters align-items-center">
                <Col lg={12}>
                  <div className="login-box">
                    <Row className="align-items-center no-gutters">
                      <Col lg={6}>
                        <div className="bg-light">
                          <div className="row justify-content-center">
                            <div className="col-lg-10">
                              <div className="home-img login-img text-center d-none d-lg-inline-block">
                                <div className="animation-2"></div>
                                <div className="animation-3"></div>
                                <img
                                  src={Feature4}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <Row className="justify-content-center">
                          <Col lg={11}>
                            <div className="p-4">
                              <div className="p-3 custom-form">
                                <Form
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <motion.div
                                    // className="col-md-6 offset-md-3"
                                    initial={{
                                      x: '-100vw',
                                    }}
                                    animate={{
                                      x: 0,
                                    }}
                                    transition={{
                                      stiffness: 150,
                                    }}
                                  >
                                    <FormGroup controlId="phone">
                                      <Label>Mobile Number</Label>
                                      <FormControl
                                        type="text"
                                        name="phone"
                                        placeholder="Enter your mobile number"
                                        autoComplete="off"
                                        {...register('phone', {
                                          required:
                                            'First name is required.',
                                          message:
                                            'First Name should contain only alphabets.',
                                        })}
                                        onChange={validateMobile}
                                        className={`${errors} ? "input-error" : "" ${mobile.cl}`}
                                      />
                                      <div
                                        className={`validateMessage ${
                                          !mobile.validated
                                            ? 'text-danger'
                                            : ''
                                        }`}
                                      >
                                        {mobile.message}
                                      </div>
                                      {errors && (
                                        <p className="errorMsg">
                                          {errors}
                                        </p>
                                      )}
                                      {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className='form-validation'>Enter your 10 digit number </Form.Label></li></ul>
           */}
                                    </FormGroup>

                                    <FormGroup controlId="pan">
                                      <Label>PAN Card number</Label>
                                      <FormControl
                                        type="text"
                                        name="pan"
                                        placeholder="Enter your PAN number"
                                        autoComplete="off"
                                        {...register('pan', {
                                          required:
                                            'First name is required.',
                                          message:
                                            'First Name should contain only alphabets.',
                                        })}
                                        onChange={validatePAN}
                                        className={`${errors} ? "input-error" : "" ${pan.cl}`}
                                      />
                                      <div
                                        className={`validateMessage ${
                                          !pan.validated
                                            ? 'text-danger'
                                            : ''
                                        }`}
                                      >
                                        {pan.message}
                                      </div>
                                      {errors && (
                                        <p className="errorMsg">
                                          {errors}
                                        </p>
                                      )}
                                      {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className='form-validation mb-0 '>UPPERCASE only</Form.Label></li>
            <li>  <Form.Label className='form-validation'> "Example - EULPM8965L"</Form.Label> </li>
            </ul>
           */}
                                    </FormGroup>

                                    <FormGroup controlId="aadhar">
                                      <Label>
                                        Aadhar Card Number
                                      </Label>
                                      <FormControl
                                        type="number"
                                        name="aadhar"
                                        placeholder="XXXXXXXXXXXX"
                                        autoComplete="off"
                                        {...register('aadhar', {
                                          required:
                                            'First name is required.',
                                          message:
                                            'First name should contain only characters.',
                                        })}
                                        onChange={validateAadhar}
                                        className={`${errors} ? "input-error" : "" ${aadhar.cl}`}
                                      />
                                      <div
                                        className={`validateMessage ${
                                          !aadhar.validated
                                            ? 'text-danger'
                                            : ''
                                        }`}
                                      >
                                        {aadhar.message}
                                      </div>
                                      {errors && (
                                        <p className="errorMsg">
                                          {errors}
                                        </p>
                                      )}
                                      {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className='form-validation mb-0'>Enter 12 digit number without spaces</Form.Label></li>
            <li><Form.Label className='form-validation mb-0 '>Example - 423142314231</Form.Label></li>
            </ul>
         */}
                                    </FormGroup>

                                    <FormGroup controlId="age">
                                      <Label>Age</Label>
                                      <FormControl
                                        type="text"
                                        name="Age"
                                        placeholder="Age"
                                        autoComplete="off"
                                        {...register('age', {
                                          required:
                                            'First name is required.',
                                          message:
                                            'First name should contain only characters.',
                                        })}
                                        onChange={validateAge}
                                        className={`${errors} ? "input-error" : "" ${age.cl}`}
                                      />
                                      <div
                                        className={`validateMessage ${
                                          !age.validated
                                            ? 'text-danger'
                                            : ''
                                        }`}
                                      >
                                        {age.message}
                                      </div>
                                      {errors && (
                                        <p className="errorMsg">
                                          {errors}
                                        </p>
                                      )}
                                      {/* <ul className="ml-4 " style={{listStyleType:"disc"}}>
            <li><Form.Label className='form-validation mb-0 '>Should be above 18 years</Form.Label></li></ul>
           */}
                                    </FormGroup>
                                    {/* <FormGroup controlId="designation">
          {isLoading && (
            <p className="loading">Loading destinations. Please wait...</p>
          )}
          <Form.Label>Designation</Form.Label>
          <Form.Control
            as="select"
            name="designation"
            value={selectedDesignation}
            onChange={(event) => setSelectedDesignation(event.target.value)}
          >
            {designation.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </Form.Control>
        </FormGroup> */}

                                    <FormGroup controlId="gender">
                                      {isLoading && (
                                        <p className="loading">
                                          Loading genders. Please
                                          wait...
                                        </p>
                                      )}
                                      <Label>Gender</Label>
                                      <FormControl
                                        as="select"
                                        name="gender"
                                        value={selectedGender}
                                        onChange={(event) =>
                                          setSelectedGender(
                                            event.target.value
                                          )
                                        }
                                        onClick={handleGenderUI}
                                        className={`${genderUI}`}
                                      >
                                        {gender.map(
                                          ({ id, name }) => (
                                            <option
                                              value={id}
                                              key={id}
                                            >
                                              {name}
                                            </option>
                                          )
                                        )}
                                      </FormControl>
                                    </FormGroup>

                                    {/* ================================================================== */}

                                    <FormGroup controlId="">
                                      <Label>Image</Label>
                                      <FormControl
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        placeholder="Image"
                                        id="image"
                                        autoComplete="off"
                                        onChange={handleImage}
                                        className={`${errors} ? "input-error" : "" ${imageValidation.cl}`}
                                      />
                                      <div
                                        className={`validateMessage ${
                                          !age.validated
                                            ? 'text-danger'
                                            : ''
                                        }`}
                                      >
                                        {imageValidation.message}
                                      </div>
                                    </FormGroup>

                                    {/* ================================================================= */}

                                    <Button
                                      id="btn"
                                      variant="primary"
                                      type="submit"
                                    >
                                      Next
                                    </Button>
                                  </motion.div>
                                </Form>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SecondStep;
