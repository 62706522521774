import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import CountUp from 'react-countup';

import { Link } from "react-router-dom";
import HomeUrl from '../../assets/images/home-border.png';

export default class FounderA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'service1',
          icon: 'mdi-numeric-1-circle',
          title: "Know what’s around",
          description: "Have startup knowledge at your fingertips. All reports and user traction are managed in one place. Control the strength of your startup."
        },
        {
          id: 'service2',
          icon: 'mdi-numeric-2-circle',
          title: "Tracking without the cost",
          description: ' It might be difficult to keep track of everything. Prioritise your metrics and make the most of our tools.'
        },
        {
          id: 'service3',
          icon: 'mdi-numeric-3-circle',
          title: "It never gets complicated",
          description: "Every deal you complete has simple paperwork. Put in your details and that's all."
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section "  id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4"></h3>
                  <h2 className="text-muted f-17 mt-3">
                  A WORD FROM OUR EXPERTS
                  </h2>
                  <p>“Bizdateup makes your fundraising experience the smoothest. Furthermore, the ability to build automatic reports based on analytics is just what I was searching for. Let's see what else BizDateUp has in store for us.” </p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4 h-100">
                    <div className="services-icon bg-soft-primary">
                      <i className={"mdi text-primary " + item.icon}></i>
                    </div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p className="text-muted mt-3">{item.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
