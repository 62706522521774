import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from '../component/Navbar/NavBar';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import thank from '../thank.json';

function Thankyou() {
    const items = [
        // { id: 1, idnm: 'home', navheading: 'Home' },
        // { id: 3, idnm: 'services', navheading: 'Services' },
        // { id: 4, idnm: 'pricing', navheading: 'Pricing' },
        // { id: 5, idnm: 'team', navheading: 'Team' },
        // { id: 6, idnm: 'clients', navheading: 'Clients' },
        // { id: 7, idnm: 'contact', navheading: 'Contact' },
    ];

    let history = useHistory();

    const [navItems, setNavItems] = useState(items);
    const [pos, setPos] = useState(document.documentElement.scrollTop);
    const [imglight, setImgLight] = useState(false);
    const [navClass, setNavClass] = useState('');
    const [fixTop, setFixTop] = useState(true);

    // useEffect(() => {
    //     window.addEventListener('scroll', scrollNavigation, true);

    //     return () => {
    //         window.removeEventListener('scroll', scrollNavigation, true);
    //     };
    // });

    const redirect = () => {
        history.push('/deals');
    };

    useEffect(() => {
        setTimeout(redirect, 3000);
    }, []);

    const scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 100) {
            setNavClass('nav-sticky');
            setImgLight(true);
        } else {
            setNavClass('');
            setImgLight(false);
        }
    };

    return (
        <div>
            <Navbar
                navItems={navItems}
                navClass={navClass}
                imglight={imglight}
                top={fixTop}
            />
            <div id='anim'></div>
            <section className='lottie'>
                <Player
                    autoplay
                    // loop

                    speed='0.7'
                    src={thank}
                    style={{ height: '500px', width: '500px' }}
                ></Player>
                <button>clickme</button>
            </section>
        </div>
    );
}

export default Thankyou;
