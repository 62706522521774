import React from 'react'
import Card from '../pages/Layout2/Card'
import { Link } from "react-router-dom";


import { Col, Container, Row, Button } from "reactstrap";


const content=[

  {
          id: 1,
          name: 'Startup Beginner Plan',
          name1: '25000',
          description: 'Website/App',
          description1: 'Mentorships',
          description2: 'Marketing',
          description3: 'Revenue Model',
          description4: '50 Angel Connections',
          link: 'https://www.bizdateup.com/ContactUs'
        },
        {
          id: 2,
          name: 'Startup Pro Plan',
          name1: 'Contact now',
          description: 'Website/App',
          description1: ' 1-1 Mentorships',
          description2: 'Personalised Marketing',
          description3: 'Revenue Model',
          description4: '200+ Angel Connections',
          link: 'https://www.bizdateup.com/ContactUs'
        }
        
]


const Team = () => {
  return (
    <>


<section className="section pt-0" style={{padding: '0px 0px 50px 0px'}}>
          <Container>
            <Row className="mt-5 pt-4">
{
  content.map(({id,name,description,name1,description1,description2,description3,description4,link})=>(

              <Col lg="6" key={id}>
                 <div style={{backgroundColor:'#F4F5F7',borderRadius:'15px'}}>
      <Card>
      <h5 className="mt-2" style={{color:'#202054'}}>{name}</h5>
                <p className="text-muted mt-3">{description}</p>
                <p className="text-muted mt-3">{description1}</p>
                <p className="text-muted mt-3">{description2}</p>
                <p className="text-muted mt-3">{description3}</p>
                <p className="text-muted mt-3">{description4}</p>
                <Button style={{color:'#ffffff',backgroundColor:'#202054'}}>
                      <a
                          href={link}
                          
                          className="text-reset"
                        >{name1}</a></Button>
      </Card>
                </div>
              </Col>
  ))
}


            </Row>
          </Container>
        </section>
    </>
  )
}

export default Team;