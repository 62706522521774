import React, { useState, useEffect } from 'react';
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv';
import Navbar from '../component/Navbar/NavBar';
import axios from 'axios';
import { BASE_API_URL } from '../utils/constants';
// import '../assets/css/admin.css';

function AdminDatabase() {
  const [database, setDatabase] = useState([]);
  const [page, setPage] = useState(0);
  const [pageLength, setPageLength] = useState(20);
  const [totalRow, setTotalRow] = useState(0);

  const [seletedRow, setSelectedRow] = useState({
    // aadhar: '',
    // age: '',
    // createdAt: '',
    // first_name: '',
    // genders: '',
    // isAadharVersion: false,
    // isPanVersion: false,
    // last_name: '',
    // pan: '',
    // phone: '',
    // updatedAt: '',
    // user_email: '',
    // user_password: '',
    // _id: '',
  });

  useEffect(() => {
    console.log(seletedRow.first_name);
  }, [seletedRow]);

  const headers = [
    { label: 'ObjectId', key: '_id' },
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Phone', key: 'phone' },
    { label: 'Email', key: 'user_email' },
    { label: 'PAN Details', key: 'pan' },
    { label: 'Aadhar Details', key: 'aadhar' },
  ];

  const downloadData = () => {
    axios
      .post(`${BASE_API_URL}/getdata`, {
        page: 0,
        pageLength: 10000000,
      })
      .then((res) => {
        // console.log(res);
        // setDatabase(res.data.query);
        // setTotalRow(res.data.documentCount);
        return res.data.query;
      });
  };

  const csvReport = {
    data: database,
    headers: headers,
    filename: 'Users_Data_Bizdateup.csv',
  };

  const items = [
    // { id: 1, idnm: 'home', navheading: 'Home' },
    // { id: 3, idnm: 'services', navheading: 'Services' },
    // { id: 4, idnm: 'pricing', navheading: 'Pricing' },
    // { id: 5, idnm: 'team', navheading: 'Team' },
    // { id: 6, idnm: 'clients', navheading: 'Clients' },
    // { id: 7, idnm: 'contact', navheading: 'Contact' },
  ];

  const [navItems, setNavItems] = useState(items);
  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImgLight] = useState(false);
  const [navClass, setNavClass] = useState('');
  const [fixTop, setFixTop] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', scrollNavigation, true);

    return () => {
      window.removeEventListener('scroll', scrollNavigation, true);
    };
  });

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 100) {
      setNavClass('nav-sticky');
      setImgLight(true);
    } else {
      setNavClass('');
      setImgLight(false);
    }
  };

  const getData = () => {
    axios
      .post(`${BASE_API_URL}/getdata`, {
        page: page,
        pageLength: pageLength,
      })
      .then((res) => {
        console.log(res);
        setDatabase(res.data.query);
        setTotalRow(res.data.documentCount);
      });
  };

  useEffect(() => {
    if (localStorage.getItem('adminPass') !== 'letmein') {
      passWord();
    } else {
      getData();
    }
  }, [pageLength, page]);

  const updateData = (e) => {
    e.preventDefault();
    console.log(seletedRow);
    axios
      .post(`${BASE_API_URL}/admin/update`, {
        data: seletedRow,
      })
      .then((res) => {
        console.log(res);
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,

          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: 'File Updated Successfully!',
        });

        const refresh = () => window.location.reload();

        setTimeout(refresh, 1500);
      })
      .catch((err) => console.log(err));
  };

  const deleteData = (e, id) => {
    e.preventDefault();
    console.log('data id deleted' + ' ' + id);
    axios
      .post(`${BASE_API_URL}/admin/delete`, {
        id: id,
      })
      .then((res) => {
        console.log(res.data.deleteCount);
        // if (res.data.deleteCount === 1) {
        console.log('delete success');
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: 'File Deleted Successfully!',
        });

        const refresh = () => window.location.reload();

        setTimeout(refresh, 1500);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const passWord = async () => {
    const { value: password } = await Swal.fire({
      title: 'Enter your Admin Password',
      input: 'password',
      inputLabel: 'Password',
      inputPlaceholder: 'Enter your password',
      inputAttributes: {
        maxlength: 10,
        autocapitalize: 'off',
        autocorrect: 'off',
      },
    });

    if (password === 'letmein') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Admin is Verified!',
        showConfirmButton: false,
        timer: 1500,
      });
      localStorage.setItem('adminPass', 'letmein');
      getData();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title:
          'Access Denied - Password Incorrect, Please Try Again.',
        showConfirmButton: false,
        timer: 1500,
      });
      const myTimeout = setTimeout(passWord, 1500);
    }
  };

  const confirmBox = (e, type) => {
    e.preventDefault();
    if (type === 'update' || type === 'delete') {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes, I'm Sure!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (type === 'update') {
            updateData(e);
          } else {
            deleteData(e, seletedRow._id);
          }
          // Swal.fire(
          //     'Deleted!',
          //     'Your file has been deleted.',
          //     'success'
          // );
        }
      });
    }
  };
  // =======================================================================

  const min = 150;
  // The max (fr) values for grid-template-columns
  const columnTypeToRatioMap = {
    numeric: 1,
    'text-short': 1.67,
    'text-long': 3.33,
  };

  const table = document.querySelector('table');
  /*
  The following will soon be filled with column objects containing
  the header element and their size value for grid-template-columns 
*/
  const columns = [];
  let headerBeingResized;

  // The next three functions are mouse event callbacks

  // Where the magic happens. I.e. when they're actually resizing
  const onMouseMove = (e) =>
    requestAnimationFrame(() => {
      // Calculate the desired width
      var horizontalScrollOffset =
        document.documentElement.scrollLeft;
      const width =
        horizontalScrollOffset +
        e.clientX -
        headerBeingResized.offsetLeft;

      /*
    Update the column object with the new size value
    NOTE: we're only fixing one column's width, not all. This is what causes the bad user experience.
  */
      const column = columns.find(
        ({ header }) => header === headerBeingResized
      );
      column.size = Math.max(min, width) + 'px'; // Enforce our minimum

      /* 
    Update the column sizes
    Reminder: grid-template-columns sets the width for all columns in one value
  */
      table.style.gridTemplateColumns = columns
        .map(({ header, size }) => size)
        .join(' ');
    });

  // Clean up event listeners, classes, etc.
  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    headerBeingResized.classList.remove('header--being-resized');
    headerBeingResized = null;
  };

  // Get ready, they're about to resize
  const initResize = ({ target }) => {
    headerBeingResized = target.parentNode;
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    headerBeingResized.classList.add('header--being-resized');
  };

  // Let's populate that columns array and add listeners to the resize handles
  document.querySelectorAll('th').forEach((header) => {
    const max = columnTypeToRatioMap[header.dataset.type] + 'fr';
    columns.push({
      header,
      // The initial size value for grid-template-columns:
      size: `minmax(${min}px, ${max})`,
    });
    header
      .querySelector('.resize-handle')
      .addEventListener('mousedown', initResize);
  });

  return (
    <div>
      <Navbar
        navItems={navItems}
        navClass={navClass}
        imglight={imglight}
        top={fixTop}
      />
      <section className="section" id="contact">
        <Container className="">
          <div className="main-body mt-5">
            <Row className="d-flex flex-row-reverse align-items-center">
              <button className="btn csv">
                <CSVLink {...csvReport}>Download Report</CSVLink>
              </button>

              <div className="dropdown mr-3">
                <button
                  className="btn  csv  dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Page Length
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li onClick={() => setPageLength(20)}>
                    <a className="dropdown-item">20 Rows</a>
                  </li>
                  <li onClick={() => setPageLength(50)}>
                    <a className="dropdown-item">50 Rows</a>
                  </li>
                  <li onClick={() => setPageLength(100)}>
                    <a className="dropdown-item">100 Rows</a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Total Row : {totalRow}
                    </a>
                  </li>
                </ul>
              </div>
            </Row>
            <Row className="gutters-sm mb-5 h-100">
             
                <table className="table-admin">
                  <thead className="thead-admin">
                    <tr className="tr-admin">
                      <th className="th-admin" data-type="numeric">
                        Object Id
                        <span className="resize-handle"></span>
                      </th>
                      <th className="th-admin" data-type="text-short">
                        Name
                        <span className="resize-handle"></span>
                      </th>
                      <th className="th-admin" data-type="text-short">
                        Mobile No.
                        <span className="resize-handle"></span>
                      </th>
                      <th className="th-admin" data-type="text-short">
                        Email
                        <span className="resize-handle"></span>
                      </th>
                      <th className="th-admin" data-type="text-long">
                        PAN Details
                        <span className="resize-handle"></span>
                      </th>
                      <th className="th-admin" data-type="text-short">
                        Aadhar Details
                        <span className="resize-handle"></span>
                      </th>
                      <th className="th-admin" data-type="text-short">
                        Actions
                        <span className="resize-handle"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tbody-admin">
                    {database.map((data) => {
                      return (
                        <tr className="tr-admin">
                          <td className="td-width overflow-hidden">
                            {data._id}
                          </td>
                          <td className="td-width overflow-hidden">
                            {data.first_name + ' ' + data.last_name}
                          </td>
                          <td className="td-width overflow-hidden">
                            {data.phone}
                          </td>
                          <td className="td-width overflow-hidden">
                            {data.user_email}
                          </td>
                          <td className="td-width overflow-hidden">
                            {data.pan}
                          </td>
                          <td className="td-width overflow-hidden">
                            {data.aadhar}
                          </td>
                          <td className="td-width overflow-hidden">
                            <span
                              onClick={() => setSelectedRow(data)}
                              type="button"
                              // className='btn-sm btn-primary'
                              className="ml-4"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              <img src="https://img.icons8.com/office/16/000000/edit.png" />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              
            </Row>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li
                className="page-item "
                onClick={() => {
                  if (page >= 1) {
                    setPage((page) => page - 1);
                  }
                }}
              >
                <a className="page-link">Previous</a>
              </li>

              <li className="page-item active px-3">
                <a className="page-link px-4" href="#">
                  {page + 1}
                </a>
              </li>

              <li
                className="page-item "
                onClick={() => {
                  if (page + 1 < totalRow / pageLength) {
                    setPage((page) => page + 1);
                  } else {
                    alert('This is last Page');
                  }
                }}
              >
                <a className="page-link " href="#">
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </Container>
      </section>

      {/* EDIT MODALS */}
      <div
        className="modal  fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Changes
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="my-3">
              <div class="row  m-2">
                <label
                  // for='inputEmail3'
                  class="col-sm-2 col-form-label"
                >
                  Object Id
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    value={seletedRow._id}
                  />
                </div>
              </div>
              <div class="row m-2">
                <label
                  // for='inputPassword3'
                  class="col-sm-2 col-form-label"
                >
                  First Name
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    id="first_name"
                    placeholder={seletedRow.first_name}
                    onChange={() =>
                      setSelectedRow((prevState) => {
                        let row = {
                          ...prevState,
                          first_name:
                            document.getElementById('first_name')
                              .value,
                        };
                        return row;
                      })
                    }
                  />
                </div>
              </div>
              <div class="row m-2">
                <label
                  for="inputPassword3"
                  class="col-sm-2 col-form-label"
                >
                  Last Name
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    id="last_name"
                    placeholder={seletedRow.last_name}
                    onChange={() =>
                      setSelectedRow((prevState) => {
                        let row = {
                          ...prevState,
                          last_name:
                            document.getElementById('last_name')
                              .value,
                        };
                        return row;
                      })
                    }
                  />
                </div>
              </div>
              <div class="row m-2">
                <label
                  for="inputPassword3"
                  class="col-sm-2 col-form-label"
                >
                  Mobile No.
                </label>
                <div class="col-sm-10">
                  <input
                    type="number"
                    class="form-control"
                    id="phone"
                    placeholder={seletedRow.phone}
                    onChange={() =>
                      setSelectedRow((prevState) => {
                        let row = {
                          ...prevState,
                          phone:
                            document.getElementById('phone').value,
                        };
                        return row;
                      })
                    }
                  />
                </div>
              </div>
              <div class="row m-2">
                <label
                  for="inputPassword3"
                  class="col-sm-2 col-form-label"
                >
                  Email
                </label>
                <div class="col-sm-10">
                  <input
                    type="email"
                    id="user_email"
                    class="form-control"
                    placeholder={seletedRow.user_email}
                    onChange={() =>
                      setSelectedRow((prevState) => {
                        let row = {
                          ...prevState,
                          user_email:
                            document.getElementById('user_email')
                              .value,
                        };
                        return row;
                      })
                    }
                  />
                </div>
              </div>
              <div class="row m-2">
                <label
                  for="inputPassword3"
                  class="col-sm-2 col-form-label"
                >
                  PAN No.
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    value={seletedRow.pan}
                  />
                </div>
              </div>
              <div class="row m-2">
                <label
                  for="inputPassword3"
                  class="col-sm-2 col-form-label"
                >
                  Aadhar No.
                </label>
                <div class="col-sm-10">
                  <input
                    type="text"
                    class="form-control"
                    value={seletedRow.aadhar}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary "
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => confirmBox(e, 'delete')}
              >
                Delete Row
              </button>
              <button
                type="button"
                className="btn btn-warning"
                onClick={(e) => confirmBox(e, 'update')}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// function SingleRow(props) {
//     // console.log(props.data._id);

//     return (
//         <tr onClick={props.onClick}>
//             <td className='td-width'>{props.data._id}</td>
//             <td className='td-width'>
//                 {props.data.first_name + ' ' + props.data.last_name}
//             </td>
//             <td className='td-width'>{props.data.phone}</td>
//             <td className='td-width'>{props.data.user_email}</td>
//             <td className='td-width'>{props.data.pan}</td>
//             <td className='td-width'>{props.data.aadhar}</td>
//             <td className='td-width'>edit</td>
//         </tr>
//     );
// }

export default AdminDatabase;
